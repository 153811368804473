<div class="mainDiv">
  <div class="cardStyle">
    <img
      *ngIf="!isUrlBasedLogo"
      src="./../../../../assets/img/ouk-logo-new.png"
      id="signupLogo"
    />
    <img
      *ngIf="isUrlBasedLogo"
      src="./../../../../assets/img/logo2-new.png"
      id="signupLogo"
    />

    <h2 class="formTitle">Vendor Registration</h2>
    <form action="" [formGroup]="myForm" (ngSubmit)="submit(myForm)">
      <div class="row" style="margin: 4px">
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Name/Organization</b><strong>:</strong></label
          >
          <input
            type="text"
            class="form-control form-control1"
            id="example1"
            placeholder=""
            formControlName="organizationName"
            disabled
          />
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Email Id</b><strong>:</strong></label
          >
          <input
            type="text"
            class="form-control form-control1"
            id="example2"
            placeholder=""
            formControlName="emailId"
            disabled
          />
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Mobile Number</b><strong>:</strong></label
          >
          <input
            type="text"
            class="form-control form-control1"
            id="example3"
            placeholder=""
            formControlName="mobileNo"
            disabled
          />
        </div>

        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Ouk Vendor Code</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="vendor_code"
            disabled
          />
          <div *ngIf="myForm.get('vendor_code')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vendor_code')?.hasError('required')"
            >
              Please Enter Ouk Vendor Code
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Organization Registration Number</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="org_reg_no"
          />
          <div *ngIf="myForm.get('org_reg_no')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('org_reg_no')?.hasError('required')"
            >
              Please Enter Organization Registration Number
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Organization Address</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="org_address"
          />
          <div *ngIf="myForm.get('org_address')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('org_address')?.hasError('required')"
            >
              Please Enter Organization Address
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>KRA Number</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="kra_no"
          />
          <div *ngIf="myForm.get('kra_no')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('kra_no')?.hasError('required')"
            >
              Please Enter KRA Number
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>IFMIS Number</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="vreg_ifmis_no"
          />
          <div *ngIf="myForm.get('vreg_ifmis_no')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_ifmis_no')?.hasError('required')"
            >
              Please Enter IFMIS Number
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>AGPO Number</b><strong>:</strong></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="vreg_agpo_no"
          />
          <div *ngIf="myForm.get('vreg_agpo_no')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_agpo_no')?.hasError('required')"
            >
              Please Enter AGPO Number
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Category</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <select class="form-select" formControlName="vreg_category_id">
            <option value="">Select</option>
            <option
              *ngFor="let item of vendorCategoryList"
              [value]="item.category_id"
            >
              {{ item.cat_name }}
            </option>
          </select>
          <div *ngIf="myForm.get('vreg_category_id')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_category_id')?.hasError('required')"
            >
              Please Enter Category
            </span>
          </div>
        </div>

        <!-- <div class="col-md-6 mb-3" *ngIf="isUploadPortfolio">
                    <label for="formFile" class="form-label">Upload Compliance
                        Documents/Certificates</label>
                    <div class="input-group">
                        <input type="file" formControlName="upload_proof" (change)="onFileChange($event)"
                            class="form-control" placeholder="Recipient's username" aria-label="Recipient's username"
                            aria-describedby="basic-addon2" #fileInputRef />
                        <span class="input-group-text" style="
                          cursor: pointer;
                          background-color: #ff7f50;
                          color: white;
                        " id="basic-addon2" (click)="removeFile('2')">Remove</span>
                    </div>
                    <div class="form-text">
                        (Upload your certificates a short courses
                        certificates/diploma/degree in single PDF file.)
                    </div>
                    <p *ngIf="file != ''">
                        {{ file }}
                    </p>
                    <div *ngIf="isProgressone" class="progress my-3">
                        <div class="progress-bar progress-bar-info progress-bar-striped" role="progressbar"
                            attr.aria-valuenow="{{ progressThree }}" aria-valuemin="0" aria-valuemax="100"
                            [ngStyle]="{ width: progressThree + '%' }">
                            {{ progressThree }}%
                        </div>
                    </div>
                </div> -->

        <div class="col-md-4 mb-3">
          <div class="form-outline">
            <label class="form-label"
              ><b>Currency</b><strong>:</strong
              ><span class="text-danger">*</span></label
            >
            <select
              class="form-select"
              formControlName="vreg_currency_id"
              disabled
            >
              <option value="">Select</option>
              <option
                *ngFor="let item of currencyList"
                [value]="item.DICTIONARYID"
              >
                {{ item.DICTIONARYNAME }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-6 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Upload Compliance Documents/Certificates</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <div class="input-group">
            <input
              type="file"
              class="form-control form-control1"
              placeholder=""
              formControlName="upload_proof"
              (change)="onFileChange($event)"
            />
            <span
              class="input-group-text"
              style="cursor: pointer; background-color: #ff7f50; color: white"
              id="basic-addon2"
              (click)="removeFile('1')"
              >Remove</span
            >
          </div>
          <h6>
            Your uploaded file,
            <a
              (click)="viewFile()"
              style="cursor: pointer; color: blue; text-decoration: underline"
            >
              click here
            </a>
            to view.
          </h6>
          <!-- <p>{{ fileInput }}</p> -->
          <div *ngIf="myForm.get('upload_proof')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('upload_proof')?.hasError('required')"
            >
              Please Upload pdf document
            </span>
          </div>
        </div>
        <h4>CONTACT PERSON :</h4>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>First Name</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="vreg_con_firstname"
          />
          <div *ngIf="myForm.get('vreg_con_firstname')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_con_firstname')?.hasError('required')"
            >
              Please Enter First Name
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Last Name</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="vreg_con_lastname"
          />
          <div *ngIf="myForm.get('vreg_con_lastname')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_con_lastname')?.hasError('required')"
            >
              Please Enter Last Name
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Email Id</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            id="example5"
            placeholder=""
            formControlName="vreg_con_emailId"
          />
          <div *ngIf="myForm.get('vreg_con_emailId')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_con_emailId')?.hasError('required')"
            >
              Please Enter Email Id
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Mobile Number</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            id="example4"
            placeholder=""
            formControlName="vreg_con_mobileNo"
          />
          <div *ngIf="myForm.get('vreg_con_mobileNo')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('vreg_con_mobileNo')?.hasError('required')"
            >
              Please Enter Mobile Number
            </span>
          </div>
        </div>

        <h4>BANK DETAILS :</h4>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Bank Name</b><strong>:</strong
            ><span class="text-danger">*</span>
          </label>
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="bank_name"
          />
          <div *ngIf="myForm.get('bank_name')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('bank_name')?.hasError('required')"
            >
              Please Enter Bank Name
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Account Number</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="account_no"
          />
          <div *ngIf="myForm.get('account_no')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('account_no')?.hasError('required')"
            >
              Please Enter Account Number
            </span>
          </div>
        </div>
        <div class="col-4 mb-3">
          <label for="exampleFormControlInput1" class="form-label mb-1"
            ><b>Branch</b><strong>:</strong
            ><span class="text-danger">*</span></label
          >
          <input
            type="text"
            class="form-control form-control1"
            placeholder=""
            formControlName="branch"
          />
          <div *ngIf="myForm.get('branch')?.touched">
            <span
              class="text-danger"
              *ngIf="myForm.get('branch')?.hasError('required')"
            >
              Please Enter Branch
            </span>
          </div>
        </div>
      </div>
    </form>
    <div class="text-center">
      <button
        type="submit"
        (click)="submit(myForm)"
        class="btn btn-primary"
        [disabled]="myForm.invalid"
      >
        Submit</button
      >&nbsp;&nbsp;
      <button type="button" (click)="close()" class="btn btn-danger">
        Close
      </button>
    </div>
  </div>
</div>
