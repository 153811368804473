<div class="wrapper pt-4">
  <!--start header -->

  <!--end header -->
  <!--start page wrapper -->
  <div class="page-wrapper">
    <div class="page-content pt-0 pe-xl-0 ps-xl-0 pe-2 ps-2">
      <div class="container-fluid">
        <div class="row g-2">
          <div class="col-xl-12 pt-3 pb-3">
            <div class="card tabs_card">
              <div class="card-body bg-white p-0">
                <ul
                  class="nav nav-pills d-flex justify-content-between"
                  id="pills-tab-cstm"
                  role="tablist"
                >
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="student_statistics-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#student_statistics"
                      type="button"
                      role="tab"
                      aria-controls="student_statistics"
                      aria-selected="true"
                    >
                      Student Statistics
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="students_revenue-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#students_revenue"
                      type="button"
                      role="tab"
                      aria-controls="students_revenue"
                      aria-selected="false"
                    >
                      Students Revenue
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="programme_statistics-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#programme_statistics"
                      type="button"
                      role="tab"
                      aria-controls="programme_statistics"
                      aria-selected="false"
                    >
                      Programme Statistics
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="university_statistics-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#university_statistics"
                      type="button"
                      role="tab"
                      aria-controls="university_statistics"
                      aria-selected="true"
                    >
                      University Statistics
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="staff_statistics-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#staff_statistics"
                      type="button"
                      role="tab"
                      aria-controls="staff_statistics"
                      aria-selected="false"
                    >
                      Staff Statistics
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <div class="tab-content mt-3 mb-3" id="pills-tab-cstmContent">
              <div
                class="tab-pane fade show active"
                id="student_statistics"
                role="tabpanel"
                aria-labelledby="student_statistics-tab"
              >
                <!-- tab-content-1 -->
                <div class="row g-2">
                  <div class="col-xl-8">
                    <div class="row">
                      <div class="col-xl-12 mb-2">
                        <div class="card card-1 card-shadow">
                          <div class="card-header">
                            <h6
                              class="float-start d-flex align-items-center mb-0"
                            >
                              <span class="member-profile text-center">
                                <img
                                  class="me-2 img-fluid"
                                  src="./../../assets/Admin dashboard/Images/signup.png"
                                  alt=""
                                />
                              </span>
                              <span class="Spanheading">Sign Up Users</span>
                            </h6>

                            <a
                              href="sign-up-users.html"
                              class="btn btncustom float-end mt-1"
                              >View All Students</a
                            >
                          </div>

                          <div class="card-body table-style">
                            <div class="row">
                              <div class="col-xl-3 col-md-6 mb-1">
                                <div class="card bg-c-lavender border-0 h-100">
                                  <div class="card-body text-center ps-2 pe-2">
                                    <span
                                      class="member-profile text-center rounded-circle p-3 light_yellow"
                                    >
                                      <img
                                        class="mb-3"
                                        src="./../../assets/Admin dashboard/Images/iwwa_year.png"
                                        alt=""
                                        class="img-fluid"
                                      />
                                    </span>
                                    <div class="text-center">
                                      <h6 class="card-title">Year Signed Up</h6>

                                      <h3>2024</h3>
                                    </div>
                                  </div>
                                  <!--//card-body-->
                                </div>
                                <!--//card-->
                              </div>
                              <div class="col-xl-3 col-md-6 mb-1">
                                <div
                                  class="card bg-c-lavender-blush border-0 h-100"
                                >
                                  <div class="card-body text-center ps-2 pe-2">
                                    <span class="member-profile text-center">
                                      <img
                                        class="mb-3"
                                        src="./../../assets/Admin dashboard/Images/mdi_register.png"
                                        alt=""
                                      />
                                    </span>
                                    <div class="text-center">
                                      <h6 class="card-title">
                                        Number Signed Up
                                      </h6>

                                      <h3>12,0889</h3>
                                    </div>
                                  </div>
                                  <!--//card-body-->
                                </div>
                                <!--//card-->
                              </div>
                              <div class="col-xl-3 col-md-6 mb-1">
                                <div
                                  class="card bg-c-alice-blue border-0 h-100"
                                >
                                  <div class="card-body text-center ps-2 pe-2">
                                    <span class="member-profile text-center">
                                      <img
                                        class="mb-3"
                                        src="./../../assets/Admin dashboard/Images/app-registration.png"
                                        alt=""
                                      />
                                    </span>
                                    <div class="text-center">
                                      <h6 class="card-title">
                                        Number Registered
                                      </h6>

                                      <h3>14,574</h3>
                                    </div>
                                  </div>
                                  <!--//card-body-->
                                </div>
                                <!--//card-->
                              </div>
                              <div class="col-xl-3 col-md-6 mb-1">
                                <div
                                  class="card bg-c-cosmic-latte border-0 h-100"
                                >
                                  <div class="card-body text-center ps-2 pe-2">
                                    <span class="member-profile text-center">
                                      <img
                                        class="mb-3"
                                        src="./../../assets/Admin dashboard/Images/other-admission.png"
                                        alt=""
                                      />
                                    </span>
                                    <div class="text-center">
                                      <h6 class="card-title">
                                        Number Admitted
                                      </h6>

                                      <h3>11,243</h3>
                                    </div>
                                  </div>
                                  <!--//card-body-->
                                </div>
                                <!--//card-->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 mb-2">
                        <div class="card card-1 card-shadow">
                          <div class="card-header">
                            <h6
                              class="float-start d-flex align-items-center mb-0"
                            >
                              <span class="member-profile text-center">
                                <img
                                  class="me-2 img-fluid"
                                  src="./../../assets/Admin dashboard/Images/Student-Registered.png"
                                  alt=""
                                />
                              </span>
                              <span class="Spanheading"
                                >Students Registered Per Programme</span
                              >
                            </h6>

                            <a
                              href="Students-Registered-per-Programme.html"
                              class="btn btncustom float-end mt-1"
                              >View All Students</a
                            >
                          </div>
                          <div class="card-body table-style">
                            <div class="mb-1">
                              <label for="" class="form-label"
                                >Select programme name *</label
                              >

                              <select
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Select programme name</option>
                                <option value="1">
                                  Bachelor of Business and Entrepreneur
                                </option>
                                <option value="2">
                                  Bachelor of Business and Entrepreneur
                                </option>
                                <option value="3">
                                  Bachelor of Business and Entrepreneur
                                </option>
                              </select>
                            </div>

                            <div class="mb-1">
                              <ul class="list-unstyled">
                                <li class="mb-1">
                                  <span
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="#B5C5F4"
                                      class="bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" /></svg
                                  ></span>
                                  Year of study : <strong>2018</strong>
                                </li>
                                <li class="mb-1">
                                  <span
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="#F5B7CD"
                                      class="bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" /></svg
                                  ></span>
                                  School name :
                                  <strong>Open University of Kenya</strong>
                                </li>
                                <li class="mb-1">
                                  <span
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="#B5C5F4"
                                      class="bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" /></svg
                                  ></span>
                                  No.of students per programme :
                                  <strong>4,234</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 mb-2">
                        <div class="card card-1 card-shadow">
                          <div class="card-header">
                            <h6
                              class="float-start d-flex align-items-center mb-0"
                            >
                              <span class="member-profile text-center">
                                <img
                                  class="me-2 img-fluid"
                                  src="./../../assets/Admin dashboard/Images/Applicants.png"
                                  alt=""
                                />
                              </span>
                              <span class="Spanheading"
                                >Applicants Under Review</span
                              >
                            </h6>

                            <button class="btn btncustom float-end mt-1">
                              View All Students
                            </button>
                          </div>
                          <div class="card-body table-style">
                            <div class="mb-2">
                              <label for="" class="form-label"
                                >Select programme name *</label
                              >

                              <select
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Select programme name</option>
                                <option value="1">
                                  Bachelor of Business and Entrepreneur
                                </option>
                                <option value="2">
                                  Bachelor of Business and Entrepreneur
                                </option>
                                <option value="3">
                                  Bachelor of Business and Entrepreneur
                                </option>
                              </select>
                            </div>

                            <div class="mb-2">
                              <ul class="list-unstyled">
                                <li class="mb-1">
                                  <span
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="#B5C5F4"
                                      class="bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" /></svg
                                  ></span>
                                  Total number of programme applied:
                                  <strong>2018</strong>
                                </li>
                                <li class="mb-1">
                                  <span
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="#F5B7CD"
                                      class="bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" /></svg
                                  ></span>
                                  Review Status :
                                  <strong class="text-warning fw-bold"
                                    >Initial Stage</strong
                                  >
                                </li>
                                <li class="mb-1">
                                  <span
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      fill="#B5C5F4"
                                      class="bi bi-circle-fill"
                                      viewBox="0 0 16 16"
                                    >
                                      <circle cx="8" cy="8" r="8" /></svg
                                  ></span>
                                  Total number of applications under review :
                                  <strong>234</strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-12 mb-2">
                        <div class="card card-1 card-shadow">
                          <div class="card-header">
                            <h6
                              class="float-start d-flex align-items-center mb-0"
                            >
                              <span class="member-profile text-center">
                                <img
                                  class="me-2 img-fluid"
                                  src="./../../assets/Admin dashboard/Images/Student-Registered.png"
                                  alt=""
                                />
                              </span>
                              <span class="Spanheading"
                                >Student Registered by Nationality</span
                              >
                            </h6>

                            <button class="btn btncustom float-end mt-1">
                              View All Students
                            </button>
                          </div>
                          <div class="card-body table-style">
                            <div class="mb-2">
                              <label for="" class="form-label"
                                >Select the country</label
                              >

                              <select
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Select programme name</option>
                                <option value="1">kenya</option>
                                <option value="2">India</option>
                                <option value="3">Canada</option>
                              </select>
                            </div>

                            <div class="mb-2">
                              <span
                                >Total Students in Kenya :
                                <strong class="text-primary">213 </strong>
                              </span>
                              <span class="ms-2"
                                >Total Programme Enrolled in Kenya
                                <strong class="text-primary">623 </strong>
                              </span>

                              <div class="table-responsive mt-2">
                                <table
                                  class="table table-sm table-bordered no-border-bottom"
                                >
                                  <tbody>
                                    <tr class="fw-bold">
                                      <td class="borderless">S.No</td>
                                      <td>Student name</td>
                                      <td>Programme Enrolled</td>
                                      <td>Admission Date</td>
                                    </tr>
                                    <tr>
                                      <td>1</td>
                                      <td>Sammuel Shekar</td>
                                      <td>BA Psychology</td>
                                      <td>23-06-2022</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>Charles Chappel</td>
                                      <td>Master of Science</td>
                                      <td>13-11-2021</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                    <tr>
                                      <td>6</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                    <tr>
                                      <td>1</td>
                                      <td>Sammuel Shekar</td>
                                      <td>BA Psychology</td>
                                      <td>23-06-2022</td>
                                    </tr>
                                    <tr>
                                      <td>2</td>
                                      <td>Charles Chappel</td>
                                      <td>Master of Science</td>
                                      <td>13-11-2021</td>
                                    </tr>
                                    <tr>
                                      <td>3</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                    <tr>
                                      <td>4</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                    <tr>
                                      <td>5</td>
                                      <td>Markas Anthony</td>
                                      <td>Communication and Technology</td>
                                      <td>31-12-2018</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="card card-1 card-shadow right-cards mb-1">
                      <div class="card-header">
                        <h6 class="mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2"
                              src="./../../assets/Admin dashboard/Images/gender.png"
                              alt=""
                            />
                          </span>
                          <span class="text-dark"
                            >Students Registered by Gender</span
                          >
                        </h6>
                      </div>
                      <div class="card-body pb-0">
                        <div class="float-end">
                          <div class="date-input-icon">
                            <input
                              type="date"
                              class="form-control"
                              id="datePicker"
                            />
                            <span class="icon">
                              <i class="bi bi-calendar"></i>
                            </span>
                          </div>
                        </div>
                        <div class="clear-fix"></div>
                        <div class="text-center">
                          <img
                            class="img-fluid w-75"
                            src="./../../assets/Admin dashboard/Images/pie_chart.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                    <div class="card card-1 card-shadow right-cards mb-1">
                      <div class="card-header">
                        <h6 class="mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2"
                              src="./../../assets/Admin dashboard/Images/age.png"
                              alt=""
                            />
                          </span>
                          <span>Students Registered by Age</span>
                        </h6>
                      </div>
                      <div class="card-body">
                        <div class="float-end">
                          <div class="date-input-icon">
                            <input
                              type="date"
                              class="form-control"
                              id="datePicker"
                            />
                            <span class="icon">
                              <i class="bi bi-calendar"></i>
                            </span>
                          </div>
                        </div>
                        <div class="clear-fix"></div>
                        <div class="text-center">
                          <img
                            class="img-fluid w-75"
                            src="./../../assets/Admin dashboard/Images/bar_chart.png"
                            alt=""
                          />
                        </div>
                      </div>
                      <div class="card-footer pb-1 pt-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                    <div class="card card-1 card-shadow right-cards mb-1">
                      <div class="card-header">
                        <h6 class="mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2"
                              src="./../../assets/Admin dashboard/Images/othericon.png"
                              alt=""
                            />
                          </span>
                          <span class="text-dark small"
                            >Applications per Semester per Programme</span
                          >
                        </h6>
                      </div>
                      <div class="card-body">
                        <select
                          class="form-select mb-3"
                          aria-label=".  example"
                        >
                          <option selected>Select Programme</option>
                          <option value="1">Programme 1</option>
                          <option value="2">Programme 2</option>
                          <option value="3">Programme 3</option>
                        </select>
                        <select
                          class="form-select mb-3"
                          aria-label=".  example"
                        >
                          <option selected>Select Semester</option>
                          <option value="1">Semester 1</option>
                          <option value="2">Semester 2</option>
                          <option value="3">Semester 3</option>
                        </select>

                        <div class="text-center">
                          <span class="fw-bold"
                            >Total number of applications of all programme and
                            semester</span
                          >
                        </div>
                        <div class="text-center mt-2 mb-2">
                          <h5 style="color: #2b748a; font-weight: 600">
                            34,224
                          </h5>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                    <div class="card card-1 card-shadow right-cards mb-1">
                      <div class="card-header">
                        <h6>
                          <span class="member-profile text-center">
                            <img
                              class="me-2"
                              src="./../../assets/Admin dashboard/Images/othericon.png"
                              alt=""
                            />
                          </span>
                          <span class="text-dark"
                            >Students Rejected per Programme</span
                          >
                        </h6>
                      </div>
                      <div class="card-body">
                        <label for="selet-rejected" class="form-label mb-0"
                          >Select the Programme</label
                        >
                        <select
                          class="form-select mb-1"
                          aria-label=".  example"
                        >
                          <option selected>Select Programme</option>
                          <option value="1">Programme 1</option>
                          <option value="2">Programme 2</option>
                          <option value="3">Programme 3</option>
                        </select>

                        <div class="mb-0 text-center">
                          <span class="fw-bold"
                            >Total number of students got
                            <span style="color: #ff0000">rejected</span> of all
                            programme</span
                          >
                        </div>
                        <div class="text-center mt-2 mb-2">
                          <h5 style="color: #ff0000; font-weight: 600">
                            3,125
                          </h5>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>

                    <div class="card card-1 card-shadow right-cards mb-3">
                      <div class="card-header">
                        <h6 class="mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2"
                              src="./../../assets/Admin dashboard/Images/othericon.png"
                              alt=""
                            />
                          </span>
                          <span class="text-dark"
                            >Students Registered per County</span
                          >
                        </h6>
                      </div>
                      <div class="card-body">
                        <label for="selet-rejected" class="form-label mb-0"
                          >Select the County</label
                        >
                        <select
                          class="form-select mb-1"
                          aria-label=".  example"
                        >
                          <option selected>Nakuru</option>
                          <option value="1">Nakuru 1</option>
                          <option value="2">Nakuru 2</option>
                          <option value="3">Nakuru 3</option>
                        </select>

                        <div class="text-center">
                          <span class="fw-bold text-center"
                            >Total number of students registered in
                            <span style="color: #2b748a">Nakuru</span>
                            county</span
                          >
                        </div>

                        <div class="text-center mt-2 mb-2">
                          <h5 style="color: #2b748a; font-weight: 600">
                            3,125
                          </h5>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <!-- tab-content-1 -->
              </div>
              <div
                class="tab-pane fade"
                id="students_revenue"
                role="tabpanel"
                aria-labelledby="students_revenue-tab"
              >
                <div class="row">
                  <div class="col-xl-12 mb-2">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/revenu.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading"
                            >Operating Revenue and Expenses</span
                          >
                        </h6>
                      </div>

                      <div class="card-body table-style">
                        <div class="mb-3">
                          <label for="" class="form-label"
                            >Select Financial Year *</label
                          >

                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Financial Year 2024 - 2025</option>
                            <option value="1">
                              Financial Year 2023 - 2024
                            </option>
                            <option value="2">
                              Financial Year 2022 - 2023
                            </option>
                          </select>
                        </div>
                        <div class="row">
                          <div class="col-xl-4 col-md-6 mb-1">
                            <div class="card bg-c-lavender border-0 h-100">
                              <div class="card-body p-4">
                                <div
                                  class="row h-100 d-flex align-items-center"
                                >
                                  <div class="col-xl-6 text-center">
                                    <span
                                      class="member-profile text-center rounded-circle light_yellow"
                                    >
                                      <img
                                        class="img-fluid w-50"
                                        src="./../../assets/Admin dashboard/Images/revenue.png"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div class="col-xl-6 text-left">
                                    <h6 class="card-title">
                                      Operating Revenue
                                    </h6>
                                    <h3>+ $ 5,178</h3>
                                  </div>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                          <div class="col-xl-4 col-md-6 mb-1">
                            <div
                              class="card bg-c-lavender-blush border-0 h-100"
                            >
                              <div class="card-body p-4">
                                <div
                                  class="row h-100 d-flex align-items-center"
                                >
                                  <div class="col-xl-6 text-center">
                                    <span
                                      class="member-profile text-center rounded-circle light_yellow"
                                    >
                                      <img
                                        class="img-fluid w-50"
                                        src="./../../assets/Admin dashboard/Images/expense.png"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div class="col-xl-6 text-left">
                                    <h6 class="card-title">
                                      Operating Expenses
                                    </h6>
                                    <h3>- $ 5,178</h3>
                                  </div>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="programme_statistics"
                role="tabpanel"
                aria-labelledby="programme_statistics-tab"
              >
                <div class="row">
                  <div class="col-xl-8 mb-2">
                    <div class="card card-1 card-shadow mb-3">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/Student-Registered.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading"
                            >Student Successfully Completed Programme by
                            Age</span
                          >
                        </h6>
                      </div>
                      <div class="card-body table-style">
                        <div class="mb-2">
                          <label for="" class="form-label"
                            >Select the Age</label
                          >

                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>25-35 Age</option>
                            <option value="1">35-45 Age</option>
                            <option value="2">45-55 Age</option>
                          </select>
                        </div>

                        <div class="mb-2">
                          <span
                            >Total Students in the age between
                            <strong class="text-danger">25-35</strong> :
                            <strong class="text-primary">213 </strong>
                          </span>
                          <span class="ms-2"
                            >Overall total students completed programme
                            <strong class="text-primary">623 </strong>
                          </span>

                          <div class="table-responsive mt-2 mb-2">
                            <table
                              class="table table-sm table-bordered no-border-bottom"
                            >
                              <tbody>
                                <tr class="fw-bold">
                                  <td class="borderless">S.No</td>
                                  <td>Programme Name</td>
                                  <td>Programme End Date</td>
                                  <td>Number of Students</td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BA Psychology</td>

                                  <td>23-06-2022</td>
                                  <td>233</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>Master of Science</td>

                                  <td>13-11-2021</td>
                                  <td>123</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>Communication and Technology</td>

                                  <td>21-02-2020</td>
                                  <td>366</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>Information Technology</td>

                                  <td>23-01-2020</td>
                                  <td>233</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>Artificial Technology</td>

                                  <td>23-06-2019</td>
                                  <td>423</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>Communication and Technology</td>

                                  <td>21-04-2019</td>
                                  <td>422</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/Student-Registered.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading"
                            >Student Successfully Completed Programme by
                            Nationality</span
                          >
                        </h6>
                      </div>
                      <div class="card-body table-style">
                        <div class="mb-2">
                          <label for="" class="form-label"
                            >Select the country</label
                          >

                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected>Kenya</option>
                            <option value="1">India</option>
                            <option value="2">USA</option>
                          </select>
                        </div>

                        <div class="mb-2">
                          <span
                            >Total Students completed programme in Kenya : 213 :
                            <strong class="text-primary">213 </strong>
                          </span>
                          <span class="ms-2"
                            >Total students completed Programme in all rhe
                            countries : 623
                            <strong class="text-primary">623 </strong>
                          </span>

                          <div class="table-responsive mt-2 mb-2">
                            <table
                              class="table table-sm table-bordered no-border-bottom"
                            >
                              <tbody>
                                <tr class="fw-bold">
                                  <td class="borderless">S.No</td>
                                  <td>Programme Name</td>
                                  <td>Programme Code</td>
                                  <td>End Date</td>
                                  <td>No.of Students</td>
                                </tr>
                                <tr>
                                  <td>1</td>
                                  <td>BA Psychology</td>
                                  <td>A23062022</td>
                                  <td>23-06-2022</td>
                                  <td>233</td>
                                </tr>
                                <tr>
                                  <td>2</td>
                                  <td>Master of Science</td>
                                  <td>D13112021</td>
                                  <td>13-11-2021</td>
                                  <td>123</td>
                                </tr>
                                <tr>
                                  <td>3</td>
                                  <td>Communication and Technology</td>
                                  <td>Y21022020</td>
                                  <td>21-02-2020</td>
                                  <td>366</td>
                                </tr>
                                <tr>
                                  <td>4</td>
                                  <td>Information Technology</td>
                                  <td>F23012020</td>
                                  <td>23-01-2020</td>
                                  <td>233</td>
                                </tr>
                                <tr>
                                  <td>5</td>
                                  <td>Artificial Technology</td>
                                  <td>C23062019</td>
                                  <td>23-06-2019</td>
                                  <td>423</td>
                                </tr>
                                <tr>
                                  <td>6</td>
                                  <td>Communication and Technology</td>
                                  <td>B21042019</td>
                                  <td>21-04-2019</td>
                                  <td>422</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div
                      class="card card-1 card-shadow"
                      style="background-color: #5b9bb0"
                    >
                      <div
                        class="card-header text-white"
                        style="background-color: #5b9bb0"
                      >
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/revenue_Course.png"
                              alt=""
                            />
                          </span>
                          <span>Course per Programme</span>
                        </h6>
                      </div>
                      <div class="card-body table-style text-white">
                        <div class="mb-3">
                          <select
                            class="form-select"
                            aria-label="Default select example"
                          >
                            <option selected="">Select Academic Year</option>
                            <option value="1">2024-25</option>
                            <option value="2">2023-24</option>
                            <option value="3">2022-23</option>
                          </select>
                        </div>

                        <div class="row">
                          <div class="col-xl-8 mb-3">
                            <span>Total number of Students</span>
                          </div>
                          <div class="col-xl-4 mb-3">
                            <h4>34,224</h4>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-xl-8 mb-3">
                            <span>Total number of Students in each course</span>
                          </div>
                          <div class="col-xl-4 mb-3">
                            <h4>34,224</h4>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-footer pt-1 pb-1"
                        style="background-color: #5b9bb0"
                      >
                        <a
                          href=""
                          class="text-decoration-none text-white float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="university_statistics"
                role="tabpanel"
                aria-labelledby="university_statistics-tab"
              >
                <div class="row">
                  <div class="col-xl-6 mb-2">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/revenu.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading">Approved School</span>
                        </h6>
                      </div>
                      <div class="card-body table-style">
                        <div class="mb-3">
                          <h6 style="color: #6e6e6e">
                            Total Number of Approved Schools :<span
                              class="text-dark"
                            >
                              24</span
                            >
                          </h6>
                        </div>

                        <div class="mb-2">
                          <ul class="list-unstyled list_cst_bults">
                            <li class="mb-1" style="--circle-color: #b5c5f4">
                              <span class="lst_title"
                                >School of Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >School Code: 235243232</small
                              >
                            </li>
                            <li class="mb-1" style="--circle-color: #f5b7cd">
                              <span class="lst_title"
                                >School of Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >School Code: 235243232</small
                              >
                            </li>
                            <li class="mb-1" style="--circle-color: #b5c5f4">
                              <span class="lst_title"
                                >School of Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >School Code: 235243232</small
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/revenu.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading">Approved Department</span>
                        </h6>
                      </div>
                      <div class="card-body table-style">
                        <div class="mb-3">
                          <h6 style="color: #6e6e6e">
                            Total Number of Approved Departments : 24 :<span
                              class="text-dark"
                            >
                              24</span
                            >
                          </h6>
                        </div>

                        <div class="mb-2">
                          <ul class="list-unstyled list_cst_bults">
                            <li class="mb-1" style="--circle-color: #b5c5f4">
                              <span class="lst_title"
                                >Information and Architecture</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >Department Code: 235243232</small
                              >
                            </li>
                            <li class="mb-1" style="--circle-color: #f5b7cd">
                              <span class="lst_title"
                                >Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >Department Code: 235243232</small
                              >
                            </li>
                            <li class="mb-1" style="--circle-color: #b5c5f4">
                              <span class="lst_title"
                                >Computer Science Engineering</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >Department Code: 235243232</small
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-2">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/revenu.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading">Approved Programme</span>
                        </h6>
                      </div>
                      <div class="card-body table-style">
                        <div class="mb-3">
                          <h6 style="color: #6e6e6e">
                            Total Number of Approved Programme :<span
                              class="text-dark"
                            >
                              24</span
                            >
                          </h6>
                        </div>

                        <div class="mb-2">
                          <ul class="list-unstyled list_cst_bults">
                            <li class="mb-1" style="--circle-color: #b5c5f4">
                              <span class="lst_title"
                                >School of Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >School Code: 235243232</small
                              >
                            </li>
                            <li class="mb-1" style="--circle-color: #f5b7cd">
                              <span class="lst_title"
                                >School of Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >School Code: 235243232</small
                              >
                            </li>
                            <li class="mb-1" style="--circle-color: #b5c5f4">
                              <span class="lst_title"
                                >School of Science and Technology</span
                              >
                              <br />
                              <small style="text-indent: 20px !important"
                                >School Code: 235243232</small
                              >
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="staff_statistics"
                role="tabpanel"
                aria-labelledby="staff_statistics-tab"
              >
                <div class="row">
                  <div class="col-xl-12 mb-3">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/signup.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading"
                            >Managerial Support Staff Per School</span
                          >
                        </h6>
                      </div>

                      <div class="card-body table-style">
                        <h6 class="mb-3" style="color: #6e6e6e">
                          Total Number of Approved Schools :<span
                            class="text-dark"
                          >
                            24</span
                          >
                        </h6>

                        <div class="row">
                          <div class="col-xl-3 col-md-6 mb-1">
                            <div class="card bg-c-lavender border-0 h-100">
                              <div class="card-body p-4">
                                <div class="mb-2">
                                  <label for="" class="form-label"
                                    >Select Gender</label
                                  >

                                  <select
                                    class="form-select"
                                    aria-label="Default select example"
                                  >
                                    <option selected="">Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                  </select>
                                </div>

                                <div class="text-center">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/pie_chart_gender.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="">
                                  <ul class="list-unstyled">
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#B5C5F4"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Male : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Female : <strong>22</strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                          <div class="col-xl-3 col-md-6 mb-1">
                            <div
                              class="card bg-c-lavender-blush border-0 h-100"
                            >
                              <div class="card-body p-4">
                                <div class="mb-2">
                                  <h6 class="text-dark">Type of Employment</h6>
                                </div>

                                <div class="text-center">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/pichart_emp.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="">
                                  <ul class="list-unstyled">
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#B5C5F4"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Contact : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Full time : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Part time : <strong>36</strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                          <div class="col-xl-3 col-md-6 mb-1">
                            <div class="card bg-c-alice-blue border-0 h-100">
                              <div class="card-body p-4">
                                <div class="mb-3">
                                  <h6 class="text-dark">
                                    Total Number of Staff
                                  </h6>
                                </div>

                                <div class="text-center mb-3">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/teacher.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="text-center">
                                  <h3>58</h3>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                          <div class="col-xl-3 col-md-6 mb-1">
                            <div class="card bg-c-cosmic-latte border-0 h-100">
                              <div class="card-body p-4">
                                <div class="mb-3">
                                  <label for="" class="form-label"
                                    >Select County *</label
                                  >

                                  <select
                                    class="form-select"
                                    aria-label="Default select example"
                                  >
                                    <option selected="">Meru</option>
                                    <option value="1">Meru 1</option>
                                    <option value="2">Meru 2</option>
                                  </select>
                                </div>

                                <div class="text-center mb-3">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/student.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="text-center">
                                  <h3>14</h3>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-3">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/signup.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading"
                            >Administrative Support Staff Per Department</span
                          >
                        </h6>
                      </div>

                      <div class="card-body table-style">
                        <div class="row">
                          <div class="col-xl-8 mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected="">Select Department</option>
                              <option value="1">Department 1</option>
                              <option value="2">Department 2</option>
                            </select>
                          </div>
                          <div class="col-xl-4 mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected="">Select year</option>
                              <option value="1">2024-25</option>
                              <option value="2">2023-24</option>
                            </select>
                          </div>
                        </div>

                        <h6 class="mb-3" style="color: #6e6e6e">
                          Total number of Support Staff :<span
                            class="text-dark"
                          >
                            58</span
                          >
                        </h6>

                        <div class="row">
                          <div class="col-xl-6 col-md-6 mb-1">
                            <div class="card bg-c-lavender border-0 h-100">
                              <div class="card-body p-4">
                                <div class="mb-2">
                                  <label for="" class="form-label"
                                    >Select Gender</label
                                  >

                                  <select
                                    class="form-select"
                                    aria-label="Default select example"
                                  >
                                    <option selected="">Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                  </select>
                                </div>

                                <div class="text-center">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/pie_chart_gender.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="">
                                  <ul class="list-unstyled">
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#B5C5F4"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Male : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Female : <strong>22</strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                          <div class="col-xl-6 col-md-6 mb-1">
                            <div
                              class="card bg-c-lavender-blush border-0 h-100"
                            >
                              <div class="card-body p-4">
                                <div class="mb-2">
                                  <h6 class="text-dark">Type of Employment</h6>
                                </div>

                                <div class="text-center">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/pichart_emp.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="">
                                  <ul class="list-unstyled">
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#B5C5F4"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Contact : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Full time : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Part time : <strong>36</strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 mb-3">
                    <div class="card card-1 card-shadow">
                      <div class="card-header">
                        <h6 class="float-start d-flex align-items-center mb-0">
                          <span class="member-profile text-center">
                            <img
                              class="me-2 img-fluid"
                              src="./../../assets/Admin dashboard/Images/signup.png"
                              alt=""
                            />
                          </span>
                          <span class="Spanheading">Full Time Lecturers</span>
                        </h6>
                      </div>

                      <div class="card-body table-style">
                        <div class="row">
                          <div class="col-xl-8 mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected="">Select Department</option>
                              <option value="1">Department 1</option>
                              <option value="2">Department 2</option>
                            </select>
                          </div>
                          <div class="col-xl-4 mb-3">
                            <select
                              class="form-select"
                              aria-label="Default select example"
                            >
                              <option selected="">Select year</option>
                              <option value="1">2024-25</option>
                              <option value="2">2023-24</option>
                            </select>
                          </div>
                        </div>

                        <h6 class="mb-3" style="color: #6e6e6e">
                          Total number of Support Staff :<span
                            class="text-dark"
                          >
                            58</span
                          >
                        </h6>

                        <div class="row">
                          <div class="col-xl-6 col-md-6 mb-1">
                            <div class="card bg-c-lavender border-0 h-100">
                              <div class="card-body p-4">
                                <div class="mb-2">
                                  <label for="" class="form-label"
                                    >Select Gender</label
                                  >

                                  <select
                                    class="form-select"
                                    aria-label="Default select example"
                                  >
                                    <option selected="">Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>
                                  </select>
                                </div>

                                <div class="text-center">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/pie_chart_gender.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="">
                                  <ul class="list-unstyled">
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#B5C5F4"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Male : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Female : <strong>22</strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                          <div class="col-xl-6 col-md-6 mb-1">
                            <div
                              class="card bg-c-lavender-blush border-0 h-100"
                            >
                              <div class="card-body p-4">
                                <div class="mb-2">
                                  <h6 class="text-dark">Type of Employment</h6>
                                </div>

                                <div class="text-center">
                                  <img
                                    src="./../../assets/Admin dashboard/Images/pichart_emp.png"
                                    alt=""
                                    class="img-fluid"
                                  />
                                </div>
                                <div class="">
                                  <ul class="list-unstyled">
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#B5C5F4"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Contact : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Full time : <strong>36</strong>
                                    </li>
                                    <li class="mb-1">
                                      <span
                                        ><svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="12"
                                          height="12"
                                          fill="#F5B7CD"
                                          class="bi bi-circle-fill"
                                          viewBox="0 0 16 16"
                                        >
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="8"
                                          ></circle></svg
                                      ></span>
                                      Part time : <strong>36</strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <!--//card-body-->
                            </div>
                            <!--//card-->
                          </div>
                        </div>
                      </div>
                      <div class="card-footer pt-1 pb-1">
                        <a
                          href=""
                          class="text-decoration-none text-primary float-end"
                          >View All</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end page wrapper -->
    <!--start overlay-->
    <div class="overlay toggle-icon"></div>
    <!--end overlay-->
    <!--Start Back To Top Button-->
    <a href="javaScript:;" class="back-to-top"
      ><i class="bx bxs-up-arrow-alt"></i
    ></a>
    <!--End Back To Top Button-->
  </div>
</div>
