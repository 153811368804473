<div class="wrapper">
  <div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
      <div class="container-fluid">
        <div class="default_class">
          <div class="content-wrapper p-0">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card rounded shadow-lg">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-xl-9 pe-lg-2 ps-lg-1">
                          <div class="pt-3 pb-3">
                            <div class="mt-3">
                              <h2 style="font-size: 22px; color: #2b3674">
                                Hi {{ userName }}
                              </h2>
                              <h2 style="font-size: 14px; color: #707eae">
                                Welcome to OUK
                              </h2>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-6 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-header border-0 pt-3 pb-3">
                                  <h5>News</h5>
                                </div>
                                <div
                                  class="card-body smooth-scroll px-0 pt-0 style-1"
                                >
                                  <div
                                    href="javascript:void(0)"
                                    class="bg-white pt-2 pb-2 ps-3 pe-3"
                                    *ngFor="let item of newsList"
                                  >
                                    <p
                                      class="title1 mb-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#newsModal"
                                      (click)="getNews(item.EVENT_ID)"
                                    >
                                      <a
                                        href="javascipt:;"
                                        style="color: #5a5a5a"
                                        >{{ item.EVENT_NAME }}</a
                                      >
                                    </p>
                                    <p class="dt-time mb-0">
                                      {{ item.EVENT_DESCRIPTION }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-xl-6 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-header border-0 pt-3 pb-3">
                                  <h5>Ongoing Sessions</h5>
                                </div>
                                <div
                                  class="card-body smooth-scroll px-0 pt-0 style-1"
                                >
                                  <div
                                    href="javascript:void(0)"
                                    class="bg-white pt-2 pb-2 ps-3 pe-3"
                                    *ngIf="
                                      course.sessions && course.sessions.length
                                    "
                                  >
                                    <h5>Online Sessions for today</h5>
                                    <!-- <p class="title1 mb-1">Class name with the extra length text will be </p> -->
                                    <div
                                      class="clearfix"
                                      *ngFor="let item of course.sessions"
                                    >
                                      <div class="float-start">
                                        <p class="dt-time mb-0">
                                          {{ item.SessionName }}
                                        </p>
                                        <p class="dt-time mb-0">
                                          {{ item.StartTime }}
                                        </p>
                                        <!-- <p class="dt-time mb-0"> {{item.EndTime}} </p> -->
                                      </div>

                                      <div class="float-end">
                                        <button
                                          class="btn btn-primary"
                                          (click)="joinSession(item)"
                                          *ngIf="item.URL"
                                        >
                                          Join Class
                                        </button>
                                        <span *ngIf="!item.URL"
                                          >Host Not Joined</span
                                        >
                                        <span
                                          *ngIf="roleId == 2 || roleId == 2066"
                                        >
                                          <button
                                            class="btn btn-primary"
                                            (click)="joinSession(item)"
                                            *ngIf="item.URL"
                                          >
                                            Start Class
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="text-dark"
                                      *ngIf="
                                        !course.sessions ||
                                        !course.sessions.length
                                      "
                                    >
                                      No records Display
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12 mb-4" *ngIf="roleId == 3">
                              <div class="card card-1 card-shadow">
                                <div class="card-header border-0 pt-3 pb-3">
                                  <h5>Assessment</h5>
                                </div>
                                <div class="card-body table-style px-0 pt-0">
                                  <div class="bg-white pt-2 pb-2 ps-3 pe-3">
                                    <div
                                      href="javascript:void(0)"
                                      class="clearfix"
                                      *ngFor="let item of course.classes"
                                    >
                                      <div class="float-start">
                                        <p class="title1 mb-1">
                                          {{ item.Name }}
                                        </p>
                                        <!-- <p class="dt-time mb-0"> by Prof. Lakshmi Kumari kamaneni </p> -->
                                      </div>

                                      <div class="float-end">
                                        <button
                                          class="btn btn-outline-primary mt-3"
                                          (click)="
                                            navigate(
                                              item,
                                              'HOME/components/learningmaterial'
                                            )
                                          "
                                        >
                                          View Files
                                        </button>
                                        &nbsp; &nbsp;
                                        <button
                                          class="btn btn-save mt-3"
                                          (click)="
                                            navigate(
                                              item,
                                              'HOME/components/postassessment'
                                            )
                                          "
                                        >
                                          Start Test
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- <div class="bg-white pt-2 pb-2 ps-3 pe-3">

                  <div class="clearfix">
                    <div class="float-start">
                      <p class="title1 mb-1">Class name with the extra length text will be </p>
                      <p class="dt-time mb-0"> by Prof. Lakshmi Kumari kamaneni </p>

                    </div>

                    <div class="float-end">
                      <button class="btn   btn-outline-primary mt-3">View Files</button> &nbsp; &nbsp;
                      <button class="btn   btn-primary mt-3">Start Test</button>
                    </div>

                  </div>
                </div> -->

                                  <div class="text-center">
                                    <!-- <a href="#" style="text-decoration: none; color:#9C9C9D; font-size: 13px;">View all <i
                      class="bx bx-right-arrow-alt"></i></a> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-12 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-body table-style">
                                  <nav class="tabs-style">
                                    <div class="float-start pt-2">
                                      <h5>My Programme</h5>
                                    </div>
                                    <div
                                      class="nav nav-tabs border-0 float-end"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      <!-- <button class="nav-link active border-0" id="nav-all-tab" data-bs-toggle="tab"
                      data-bs-target="#nav-all" type="button" role="tab" aria-controls="nav-all"
                      aria-selected="true">All</button> -->
                                      <!-- <button class="nav-link border-0" id="nav-ongoing-tab" data-bs-toggle="tab"
                      data-bs-target="#nav-ongoing" type="button" role="tab" aria-controls="nav-ongoing"
                      aria-selected="false">Ongoing</button> -->
                                      <!-- <button class="nav-link border-0" type="button">Ongoing</button> -->
                                      <!-- <button class="nav-link border-0" id="nav-meetings-tab" data-bs-toggle="tab"
                      data-bs-target="#nav-meetings" type="button" role="tab" aria-controls="nav-meetings"
                      aria-selected="false">Completed</button> -->
                                    </div>
                                  </nav>
                                  <div
                                    class="tab-content mt-5"
                                    id="nav-tabContent"
                                  >
                                    <div
                                      class="tab-pane fade show active"
                                      id="nav-all"
                                      role="tabpanel"
                                      aria-labelledby="nav-all-tab"
                                      style="display: inline-block"
                                    >
                                      <div class="table-responsive">
                                        <table
                                          class="table table-borderless"
                                          style="width: 100%"
                                        >
                                          <tbody>
                                            <tr *ngFor="let item of cources">
                                              <td class="pt-4">
                                                <span
                                                  class="mt-2"
                                                  style="
                                                    background-color: #f6f8fd;
                                                    border-radius: 50%;
                                                    padding: 1rem;
                                                  "
                                                >
                                                  <img src="" class="" />
                                                </span>
                                              </td>
                                              <td>
                                                <p
                                                  class=""
                                                  style="
                                                    margin: 0px !important ;
                                                  "
                                                >
                                                  {{ item.COURSE_NAME }}
                                                </p>
                                                <!-- <span class="sub-title pt-0">Class</span> -->
                                              </td>
                                              <td
                                                class="pt-3"
                                                style="width: 25%"
                                              >
                                                <div class="progress progress1">
                                                  <div
                                                    class="progress-bar"
                                                    role="progressbar"
                                                    style="width: 90%"
                                                    aria-valuenow="90"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                  ></div>
                                                </div>
                                              </td>
                                              <td class="pt-3">
                                                <i class=""></i> <span></span>
                                              </td>
                                              <td
                                                class="btn-rounded-1 pt-2"
                                                style="width: 20%"
                                              >
                                                <button
                                                  type="button"
                                                  class="btn btn-outline-primary"
                                                >
                                                  Ongoing Program
                                                </button>
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>

                                      <div class="float-end">
                                        <!-- <a href="#" class="btn  "
                        style="text-decoration: none; color:#0066FF; font-size: 16px;font-weight:500; ">View all
                        <i class="bx bx-right-arrow-alt"></i></a> -->
                                      </div>
                                    </div>
                                    <div
                                      class="tab-pane fade"
                                      id="nav-ongoing"
                                      role="tabpanel"
                                      aria-labelledby="nav-ongoing-tab"
                                    >
                                      45
                                    </div>
                                    <div
                                      class="tab-pane fade"
                                      id="nav-meetings"
                                      role="tabpanel"
                                      aria-labelledby="nav-meetings-tab"
                                    >
                                      89
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-3 bg-white">
                          <div>
                            <div data-simplebar="true">
                              <div
                                style="
                                  background-color: #fafafa;
                                  border-radius: 20px;
                                "
                                class="p-1 mb-3 mt-3 ps-2 pe-2"
                              >
                                <div class="app">
                                  <div class="app__main shadow-sm mt-3">
                                    <div class="calendar">
                                      <div id="calendar"></div>
                                    </div>
                                  </div>
                                </div>
                                <div class="upcoming mt-3" *ngIf="false">
                                  <h6>Upcoming</h6>
                                  <p>18-03-2022</p>

                                  <ul>
                                    <li>DBMS Assignment</li>
                                    <li>Cyber Law class</li>
                                  </ul>
                                </div>
                              </div>

                              <!-- <div class="card card-1 card-shadow right-cards mb-2">

              <div class="card-body">
                <div class="text-center">
                  <h6>What sessions would you like to prefer?</h6>
                  <div class="btn-rounded-1 mt-4">
                  <button type="button" class="btn btn-outline-primary px-4 py-1 me-2">Online</button>
                  <button type="button" class="btn btn-outline-primary px-4 py-1 " (click)="polls()">&nbsp; Take
                    Polls &nbsp;</button>
                  </div>
                </div>
              </div>
            </div> -->

                              <!-- 
            <div class="card card-1 card-shadow right-cards mb-3">

              <div class="card-body">
                <div class="text-center">
                  <h6>What sessions would you like to prefer?</h6>
                  <div class="btn-rounded-1 mt-4">
                  <button type="button" class="btn btn-outline-primary px-4 py-1 " (click)="surveys()">Take
                    Surveys</button>
                  <button type="button" class="btn btn-outline-primary px-4 py-1">Offline</button>
                  </div>
                </div>
              </div>
            </div> -->

                              <!-- <a href="#" class="btn   small"
              style="text-decoration: none; color:#0066FF; font-size: 12px;font-weight:500; ">View all <i
                class="bx bx-right-arrow-alt"></i></a> -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!----------------------------------  Start News PopUp  --------------------------------->

<div
  class="modal fade"
  id="newsModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header py-2">
        <h4 class="modal-title w-100">news</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal">
          &times;
        </button>
        <!-- <h4 class="modal-title" >Upcoming Event</h4> -->
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
      </div>
      &nbsp;
      <div *ngIf="isNews">
        <div class="container">
          <div class="row col-12">
            <div class="col-3">
              <img src="{{ news.EVENT_IMAGE }}" alt="News" />
            </div>
            <div class="col-9">
              <h3>{{ news.EVENT_NAME }}</h3>
              <!-- <h5 class="description">{{news.EVENT_DESCRIPTION}}</h5> -->
              <P class="description">{{ news.EVENT_DESCRIPTION }}</P>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        &nbsp;
        <button
          class="btn btn-danger"
          id="close"
          (click)="close()"
          data-bs-dismiss="modal"
          (click)="close()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!------------------------------- End Start News PopUp  --------------------------------->
