<div class="wrapper">
  <div class="page-wrapper pt-4">
    <div class="pt-0">
      <div class="default_class">
        <div class="content-wrapper p-0">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-12">
                <div class="card rounded shadow-lg">
                  <div
                    class="card-header headerbg"
                    style="border-bottom: 3px solid #d8eff8"
                  >
                    <span class="float-start">
                      <h5 class="mb-0">Speech To Text</h5>
                    </span>
                    <span class="float-end">
                      <button (click)="back()" class="btn btn-outline-danger">
                        Back
                      </button>
                    </span>
                  </div>
                  <div class="card-body">
                    <div class="card-body">
                      <div style="margin: 50px">
                        <!-- <h2>Voice recognition demo</h2> -->
                        <button
                          type="button"
                          [disabled]="isStartSpeech"
                          class="btn btn-success"
                          style="margin: 10px"
                          id="startSpeech"
                          (click)="startService()"
                        >
                          Start Speech
                        </button>
                        <button
                          type="button"
                          [disabled]="!isStartSpeech"
                          id="stopSpeech"
                          class="btn btn-danger"
                          style="margin: 10px"
                          (click)="stopService()"
                        >
                          Stop Speech
                        </button>
                        <button
                          type="button"
                          [disabled]="
                            service.speechMessage.length == 0 || disableButtons
                          "
                          class="btn btn-warning"
                          style="margin: 10px"
                          (click)="download()"
                        >
                          download
                        </button>
                        <button
                          type="button"
                          [disabled]="
                            service.speechMessage.length == 0 || disableButtons
                          "
                          class="btn btn-danger"
                          style="margin: 10px"
                          (click)="ClearText()"
                        >
                          Clear
                        </button>
                        <p>{{ service.speechMessage }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
