<section
  [ngClass]="{ 'pt-1': !isShowAll }"
  [ngStyle]="{ 'padding-top': isShowAll ? '7rem' : null }"
>
  <div class="container post pt-1">
    <div class="row DCB-card" style="padding-top: 1.25rem !important">
      <div class="accordion" id="accordionExample">
        <div
          class="accordion-item"
          *ngFor="
            let courseType of groupedCourses | keyvalue : courseGraduationOrder;
            let i = index
          "
        >
          <h2 class="accordion-header" [id]="'heading' + i">
            <button
              class="accordion-button fw-bold"
              type="button"
              data-bs-toggle="collapse"
              style="font-size: large"
              [attr.data-bs-target]="'#collapse' + i"
              aria-expanded="true"
              [attr.aria-controls]="'collapse' + i"
            >
              {{ courseType.key }}
            </button>
          </h2>
          <div
            [id]="'collapse' + i"
            class="accordion-collapse collapse"
            aria-labelledby="{{ 'heading' + i }}"
            data-bs-parent="#accordionExample"
            [class.show]="i === 0"
          >
            <div class="accordion-body">
              <div class="row">
                <div
                  class="col-xl-4 col-md-6 mb-5 pt-2"
                  *ngFor="let course of courseType.value"
                >
                  <div class="card shadow-sm h-100">
                    <div style="position: relative">
                      <img
                        [src]="
                          course.COURSE_IMAGE
                            ? url + course.COURSE_IMAGE
                            : '../../../assets/img/subject-img.jpg'
                        "
                        class="card-img-top"
                        alt="..."
                        width="354"
                        height="185"
                      />
                    </div>
                    <div class="card-body">
                      <h5 class="card-title mt-2 text-start">
                        {{ course.COURSE_NAME }}
                      </h5>
                      <p class="card-text text-start mb-1 fw-bold">
                        {{ course?.COURSE_DESCRIPTION?.substr(0, 150) }}
                        <a
                          *ngIf="course.COURSE_DESCRIPTION?.length > 75"
                          (click)="gotoCourseDetail(course, 1)"
                          href="javascript:void(0);"
                          >View More</a
                        >
                      </p>
                      <div class="text-center">
                        <button
                          class="btn btn-seeall-1"
                          style="
                            background-color: #ff7f50 !important;
                            color: white;
                            width: 100%;
                          "
                          (click)="gotoCourseDetail(course, 1)"
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End course loop -->
              </div>
            </div>
          </div>
        </div>
        <!-- End accordion-item loop -->
      </div>
    </div>
  </div>
</section>
