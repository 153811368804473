import { CommonService } from "./../../services/common.service";
import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { ICourse } from "../../components/Shared/models/course";
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-all-courses",
  templateUrl: "./all-courses.component.html",
  styleUrls: ["./all-courses.component.css"],
})
export class AllCoursesComponent implements OnInit {
  @Input() courseList: Array<ICourse> = [];
  undergraduateCourses: Array<ICourse> = [];
  postgraduateCourses: Array<ICourse> = [];
  shortCourses: Array<ICourse> = [];
  @Input() isShowAll: boolean = false;
  url: string = environment.videoUrl;
  showShortCourses: boolean = false;
  groupedCourses: { [key: string]: any[] } = {};

  constructor(
    private route: Router,
    private CommonService: CommonService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    let windowUrl = window.location.href;
    if (windowUrl.includes("shortCourses")) {
      this.showShortCourses = true;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.courseList) {
      this.filterCourses();
    }
  }

  filterCourses() {
    this.courseList.sort((a, b) => a.COURSE_GRADUATION - b.COURSE_GRADUATION);

    if (this.showShortCourses) {
      this.groupedCourses = this.courseList
        .filter((course) => course.COURSE_GRADUATION === 3)
        .reduce((acc, course) => {
          if (!acc[course.COURSE_TYPE_NAME]) {
            acc[course.COURSE_TYPE_NAME] = [];
          }
          acc[course.COURSE_TYPE_NAME].push(course);
          return acc;
        }, {});
    } else {
      this.groupedCourses = this.courseList.reduce((acc, course) => {
        if (!acc[course.COURSE_TYPE_NAME]) {
          acc[course.COURSE_TYPE_NAME] = [];
        }
        acc[course.COURSE_TYPE_NAME].push(course);
        return acc;
      }, {});
    }
    // this.CommonService.activateSpinner();
    // this.undergraduateCourses = this.courseList.filter(
    //   (course) => course.COURSE_GRADUATION === 1
    // );
    // this.postgraduateCourses = this.courseList.filter(
    //   (course) => course.COURSE_GRADUATION === 2
    // );
    // this.shortCourses = this.courseList.filter(
    //   (course) => course.COURSE_GRADUATION === 3
    // );
    // this.CommonService.deactivateSpinner();
  }
  courseGraduationOrder = (a: any, b: any) => {
    const firstCourseA = a.value[0]?.COURSE_GRADUATION || 0;
    const firstCourseB = b.value[0]?.COURSE_GRADUATION || 0;
    return firstCourseA - firstCourseB;
  };

  //[routerLink] ="['/view-course-details']"
  gotoCourseDetail(course: ICourse, Id: number) {
    if (Id == 1) {
      this.CommonService.postCall(
        "Registration/CheckShortCourseSubmittedByUser",
        {
          SHORTCOURSE_ID: course.COURSE_ID,
          SHORTCOURSE_USERID: localStorage.getItem("UserId"),
        }
      ).subscribe((res: any) => {
        if (res.message) {
          return this.toastr.warning(
            "You have already applied for this Short Course. Please navigate to My Short Courses to access it."
          );
        } else {
          if (sessionStorage.UserId) {
            localStorage.setItem("cID", course.COURSE_ID) as any;
            localStorage.setItem("cName", course.COURSE_NAME);
            localStorage.setItem("appTyp", course.COURSE_GRADUATION);
            localStorage.setItem("courseFee", course.COURSE_FEES);
            // this.route.navigate(["view-course-details"], { state: course });
            this.route.navigate(["/HOME/viewProgramDetails"], {
              state: course,
            });
          }
        }
      });
    } else {
      if (sessionStorage.UserId) {
        localStorage.setItem("cID", course.COURSE_ID) as any;
        localStorage.setItem("cName", course.COURSE_NAME);
        localStorage.setItem("appTyp", course.COURSE_GRADUATION);
        localStorage.setItem("courseFee", course.COURSE_FEES);
        // this.route.navigate(["view-course-details"], { state: course });
        this.route.navigate(["/HOME/viewProgramDetails"], { state: course });
        // (localStorage.setItem('CourseFee', course.COURSE_FEES) as any);

        // localStorage.setItem('ISCLUSTER', course.IS_CLUSTER);
      } else {
        // this.route.navigate(["eRP/view-course-details"], { state: course });
        this.route.navigate(["/HOME/viewProgramDetails"], { state: course });
      }
    }
  }
}
