import { trim } from "jquery";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { BaseComponent } from "../base.component";
import { CommonService } from "../../services/common.service";
import { SamvaadUserRegister } from "../../samvaad-user.component";
import { environment } from "../../../environments/environment";
import { ISearchDropDown } from "../search-dropdown/SearchDropdown";
import { CaptchaService } from "../../services/captcha.service";
declare let $: any;

@Component({
  selector: "app-student-signup",
  templateUrl: "./student-signup.component.html",
  styleUrls: ["./student-signup.component.css"],
})
export class StudentSignupComponent implements OnInit {
  signUpForm: FormGroup;
  submitted: boolean = false;
  RegUser = new SamvaadUserRegister();
  samvaadUserPWD: string = "";
  RoleId = sessionStorage.getItem("RoleId");
  companyList: Array<any> = [];
  pay_Load: any = {};
  userResponse: string = "";
  code: string = "";
  isLoginDetailsSubmitted: boolean = false;
  apiResponse: any;
  EMAIL_ADDRESS: string | null = "";
  country: Array<any> = [];
  selectedCountry: string = "";
  countryCode: string = "";
  countrySearchData: ISearchDropDown;

  captchaQuestion: string;
  userAnswer: number;
  isCorrect: boolean | null = null;
  isUrlBasedLogo: boolean = false;
  constructor(
    private fb: FormBuilder,
    private CommonService: CommonService,
    private captchaService: CaptchaService,
    private route: Router,
    private toastr: ToastrService
  ) {
    // super(CommonService, toastr);
    this.checkUrlLogo(location.href);
    // this.getCompanyDetails();
  }

  ngOnInit(): void {
    this.validationInit();
    this.signUpOnInit();
    // this.getCompanyList();
    // setTimeout(() => this.companyDetails(), 10);
    // this.createCaptcha();
    this.loadCaptcha();

    this.loadCountry();
    this.initialData();
  }

  checkUrlLogo(url: string) {
    const urlObj = {
      dhanushFinance: "https://finance.dhanushinfotech.com/login",
      financeDemo: "https://financedemo.dhanushinfotech.com/login",
      nwhQa: "https://nwhfinanceqa.dhanushinfotech.com/login",
      nwhPreprod: "https://nwhfinancepreprod.dhanushinfotech.com/login",
      nwhProd: "https://finance.himscmchbhopal.com/login",
    };
    const { nwhQa, nwhPreprod, nwhProd, dhanushFinance, financeDemo } = urlObj;
    if (
      dhanushFinance === url ||
      financeDemo === url ||
      nwhQa === url ||
      nwhPreprod === url ||
      nwhProd === url
    ) {
      this.isUrlBasedLogo = true;
      localStorage.setItem("isUrlBasedLogo", "true");
    } else {
      this.isUrlBasedLogo = false;
      localStorage.setItem("isUrlBasedLogo", "false");
    }
  }

  initialData(): void {
    this.countrySearchData = {
      DataList: [],
      SelectedText: "COUNTRY_NAME",
      SelectedId: "COUNTRY_ID",
      DropdownTitle: "",
      EnabledOrDisabled: false,
      PatchId: "0",
      isRequried: false,
      placeholder: "Select a country",
    };
  }
  loadCaptcha(): void {
    this.captchaService.generateCaptcha();
    this.captchaQuestion = this.captchaService.getCaptchaQuestion();
  }

  speakCaptcha(): void {
    this.captchaService.speakCaptcha();
  }
  refreshCaptcha() {
    this.loadCaptcha();
  }
  signUpOnInit() {
    this.signUpForm = this.fb.group({
      FirstName: ["", [Validators.required, Validators.maxLength(100)]],
      LastName: ["", [Validators.required, Validators.maxLength(100)]],
      Mobileno: ["", [Validators.required, Validators.pattern("^[0-9]*$")]],
      UserName: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$"
          ),
        ],
      ],
      role: [true],
      Company_Name: [""],
      regType: ["1"],
      Company_id: [0],
    });
  }
  loadCountry() {
    this.CommonService.activateSpinner();
    this.country = [];
    this.CommonService.postCall("ClustersMaster/GetCountryList", {}).subscribe(
      (res: any) => {
        // this.country = res.sort((a: any, b: any) => a.COUNTRY_CONNECTING_CODE.localeCompare(b.COUNTRY_CONNECTING_CODE));
        this.country = res.sort((a: any, b: any) =>
          a.COUNTRY_NAME.localeCompare(b.COUNTRY_NAME)
        );
        this.selectedCountry = res.find(
          (e) => e.COUNTRY_NAME === "Kenya"
        )?.COUNTRY_ID;
        this.countrySearchData = {
          ...this.countrySearchData,
          DataList: this.country,
          PatchId: this.selectedCountry,
        };
        this.changeSearchDropdown(this.selectedCountry);
        this.CommonService.deactivateSpinner();
      }
    );
  }
  changeSearchDropdown(data: any) {
    this.countryCode = data.COUNTRY_ID;
  }
  // getId(event: any) {
  //   this.countryCode = this.country.find(e => e.COUNTRY_NAME == event)?.COUNTRY_ID
  //   // this.countryCode = this.country.find(e => e.COUNTRY_ID == getCountryId)?.COUNTRY_CONNECTING_CODE;
  // }
  mobileValidation(event: any) {
    if (event.target.value.charAt(0) === "0") {
      this.toastr.error("Mobile number cannot start with zero.");
      this.signUpForm.controls["Mobileno"].setValue("");
    }
  }
  onKeyPress(event: any) {
    const inputElement = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Allow only numbers (0-9) and restrict the length to 12 characters
    const charCode = event.which ? event.which : event.keyCode;
    const isNumber = charCode >= 48 && charCode <= 57;

    if (!isNumber || currentValue.length >= 12) {
      event.preventDefault();
    }

    // const charCode = event.which ? event.which : event.keyCode;

    // Allow only numbers (0-9) and navigation keys
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }
  onPaste(event: ClipboardEvent) {
    let clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedText = clipboardData.getData("text");

    // Remove any non-numeric characters from the pasted text
    const sanitizedText = pastedText.replace(/\D/g, "");

    // Get the input element and its current value
    const inputElement: any = event.target as HTMLInputElement;
    const currentValue = inputElement.value;

    // Limit the combined length of the current value and pasted text to 12 characters
    const allowedLength = 12 - currentValue.length;

    // Trim the pasted text if it exceeds the allowed length
    const trimmedText = sanitizedText.substring(0, allowedLength);

    // Prevent the default paste action
    event.preventDefault();

    // Manually set the input value with the sanitized and trimmed text
    const newValue =
      currentValue.slice(0, inputElement.selectionStart) +
      trimmedText +
      currentValue.slice(inputElement.selectionEnd);

    inputElement.value = newValue;

    // Optionally, update the form control if needed
    this.signUpForm.controls["Mobileno"].setValue(newValue);
  }
  get f() {
    return this.signUpForm.controls;
  }
  createCaptcha() {
    //clear the contents of captcha div first
    (document.getElementById("captcha") as HTMLDivElement).innerHTML = "";
    // document.getElementById('captcha')?.innerHTML = "";
    this.userResponse = "";
    var charsArray =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 6;
    var captcha: string[] = [];
    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }
    var canv = document.createElement("canvas");
    canv.id = "captcha";
    canv.width = 100;
    canv.height = 50;
    var ctx: any = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.strokeText(captcha.join(""), 0, 30);
    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    this.code = captcha.join("");
    document.getElementById("captcha")?.appendChild(canv); // adds the canvas to the body element
  }

  navigateToOuk() {
    window.open("https://ouk.ac.ke/");
  }
  validateCaptcha() {
    // if (this.signUpForm.invalid) {
    //   this.toastr.warning('Please Enter All Mandatory Fields', 'SignUp');
    //   return;
    // }
    this.isCorrect = this.captchaService.checkAnswer(+this.userAnswer);
    if (!this.isCorrect) {
      this.toastr.error("Incorrect captcha, please try again.");
      // alert('Incorrect answer, please try again.');
      return this.loadCaptcha(); // Load a new CAPTCHA if wrong
    } else {
      // alert('CAPTCHA passed!');
      this.onSubmit();
    }
  }
  onSubmit() {
    this.submitted = true;
    // if (this.signUpForm.invalid) {
    //   this.toastr.warning('Please Enter All Mandatory Fields', 'SignUp');
    //   return;
    // }

    let signUpData = this.signUpForm.getRawValue();
    if (!signUpData.role) {
      //Tutor
      if (+signUpData.regType == 2 && signUpData.Company_Name.trim() == "") {
        this.toastr.warning("Please enter the company name");
        document.getElementById("Company_Name")?.focus();
        return;
      }
    } else {
      if (+signUpData.regType == 2 && signUpData.Company_id == 0) {
        this.toastr.warning("Please select company name.");
        return;
      }
    }
    //console.log('signup user :', this.signUpForm.getRawValue().trim());
    let [payLoad, URL] = [{}, ""];

    const TENANT_CODE = this.getTenantCode(+sessionStorage.company_id);
    if (!signUpData.role) {
      //Tutor Level
      URL = "TenantRegistration/Create";
      payLoad = {
        TNT_NAME: signUpData.name.trim(),
        TNT_STATUS: true,
        TenantLogoUrl: "",
        SHOWLOGO: false,
        allow_proctoring: false,
        allow_exampad: false,
        TNT_CREATEDBY: "12345678",
        Mobileno: signUpData.mobile.trim(),
        emailid: signUpData.email.trim(),
        Company_Name: signUpData.Company_Name.trim() || "",
        RoleId: +signUpData.regType == 1 ? 25 : 24,
        country_id: this.countryCode,
        MobileNo_Code_Id: this.countryCode,
        // RoleId: 25//Tutor
      };
      // console.log(payLoad);
    } else {
      //Student Level
      URL = "Registration/SaveRegistration";
      payLoad = {
        title: 38,
        FirstName: signUpData.FirstName,
        LastName: signUpData.LastName,
        Mobileno: signUpData.Mobileno,
        UserName: signUpData.UserName,
        // password: "abc123",
        // confirmPassword: "abc123",
        // dob: "0001-01-01",
        // Gender: 0,
        TENANT_CODE: 88968955,
        UserType: 26, //Student
        // Role: 3,//Member Or Trainee Or Student
        Role: 3, //Member Or Student
        Timezone: "India Standard Time",
        CREATEDBY: 88968955,
        Company_id: +sessionStorage.company_id,
        country_id: this.countryCode,
        MobileNo_Code_Id: this.countryCode,
      };
    }

    this.pay_Load = payLoad;
    //Open Address Model
    this.getAddressDetails(this.pay_Load);
    this.isLoginDetailsSubmitted = true;
  }
  validateEmail(event: any) {
    let val = event.target.value;
    let exp = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;

    if (new RegExp(exp).test(val)) {
      // Check if there are numbers after "@" symbol
      let parts = val.split("@");
      if (parts.length === 2 && /\d/.test(parts[1])) {
        this.EMAIL_ADDRESS = null;
        this.toastr.warning('Numbers are not allowed after "@"');
        return;
      } else {
        this.EMAIL_ADDRESS = val;
      }
    } else {
      this.EMAIL_ADDRESS = null;
      this.toastr.warning(
        "Invalid email credentials, please enter a valid email"
      );
      return;
    }
  }
  getAddressDetails(data: any) {
    this.isLoginDetailsSubmitted = false;
    // console.log("Address Details:=", data);
    if (data == false || data == null) return;
    let URL = "Registration/SaveRegistration";
    let signUpData = this.signUpForm.getRawValue();
    // console.log(this.pay_Load);
    let value = this.pay_Load;
    if (value.FirstName == "") {
      this.toastr.warning("First Name is Mandatory");
      return;
    } else if (value.LastName == "") {
      this.toastr.warning("Last Name is Mandatory");
      return;
    } else if (
      value.country_id == 0 ||
      value.country_id == null ||
      value.country_id == undefined
    ) {
      this.toastr.warning("Country is Mandatory");
      return;
    } else if (
      value.MobileNo_Code_Id == 0 ||
      value.MobileNo_Code_Id == null ||
      value.MobileNo_Code_Id == undefined
    ) {
      this.toastr.warning("Mobile code is Mandatory");
      return;
    } else if (value.Mobileno == "") {
      this.toastr.warning("Mobile Number is Mandatory");
      return;
    } else if (
      value.UserName == "" ||
      value.UserName == undefined ||
      value.UserName == null
    ) {
      this.toastr.warning("Email is Mandatory");
      return;
    }
    this.CommonService.activateSpinner();
    this.CommonService.postCall(URL, this.pay_Load).subscribe(
      (res: any) => {
        if (
          +this.pay_Load.UserType == 26 &&
          res.message == "Registration Successful"
        ) {
          data.TENANTCODE = res.TENANT_CODE;
          data.USERID = res.userID;
          this.CommonService.postCall("Account/UpdateAddress", data).subscribe(
            (res) => {
              this.toastr.success("Created Successfully");
            }
          );
        }

        if (this.pay_Load["RoleId"] == 25) {
          this.checkSamvaadUserExists(signUpData.email, this.pay_Load, res);
        }
        this.clear();
        this.CommonService.deactivateSpinner();
        this.toastr.success(
          "Your registration has been successful! Please check you email for login credentials."
        );

        setTimeout(() => {
          this.route.navigate(["/login"]);
        }, 1000);
        setTimeout(() => {
          this.route.navigate(["/login"]);
        }, 500);
      },
      (err) => {
        this.toastr.error(err.error.MESSAGE);
        this.CommonService.deactivateSpinner();
      }
    );
  }

  getTenantCode(Company_id: number): number {
    let tenant_code = 0;
    const result = this.companyList.find((e) => e.COMPANY_ID == Company_id);
    if (result) tenant_code = result.TNT_CODE;
    return tenant_code;
  }

  checkSamvaadUserExists(
    loginEmail: string,
    payLoad: any,
    Data: any,
    isNewUser: boolean = false
  ) {
    this.CommonService.activateSpinner();
    this.CommonService.getCall(
      `nojwt/login/getUserDeatilsBy/${loginEmail}`,
      "",
      true
    ).subscribe(
      (res: any) => {
        if (isNewUser) {
          if (res.data.length > 0) {
            const { password } = res.data[0];
            this.samvaadUserPWD = password;
            this.SaveSamvaadUser(
              loginEmail,
              this.samvaadUserPWD,
              Data.TNT_CODE
            );
          }
        } else {
          if (res.data.length > 0) {
            const { password } = res.data[0];
            this.samvaadUserPWD = password;
            this.registerAsSamvaadUser(payLoad, Data, true, password);
          } else this.registerAsSamvaadUser(payLoad, Data);
        }

        this.clear();
        this.CommonService.deactivateSpinner();
        this.route.navigate(["/login"]);
      },
      (err) => {
        this.toastr.error(err.error ? err.error.text || err.error : err);
        this.CommonService.deactivateSpinner();
      }
    );
  }

  registerAsSamvaadUser(
    item: any,
    data: any,
    isExistingSamvaadUser = false,
    pwd: string = ""
  ) {
    if (isExistingSamvaadUser == false) {
      let payLoad = {
        ...this.RegUser,
        name: item["TNT_NAME"],
        // mobileNO: item['MobileNo'],
        email: item["emailid"],
        password: data.PWD,
      };
      this.CommonService.postCall(
        "nojwt/login/saveOrUpdate",
        payLoad,
        true
      ).subscribe(
        (res: any) => {
          // console.log(res);
          this.checkSamvaadUserExists(item.emailid, null, data, true);
        },
        (e) => {}
      );
    } else
      this.SaveSamvaadUser(item.emailid, this.samvaadUserPWD, data.TNT_CODE);
  }

  SaveSamvaadUser(USERNME: string, PASSWORD: string, TNT_CODE: string) {
    let insertPayload = {
      USERNME,
      PASSWORD,
      TNT_CODE,
    };

    this.CommonService.postCall(
      "Registration/InsertSamvaadUser",
      insertPayload
    ).subscribe(
      (res: any) => {
        // console.log(res);
      },
      (e) => {}
    );
  }
  cancel() {}

  clear() {
    this.signUpOnInit();
    this.submitted = false;
  }

  validationInit() {
    let self = this;
    $(".input100").each(function () {
      $(this).on("blur", function () {
        if ($(this).val().trim() != "") {
          $(this).addClass("has-val");
        } else {
          $(this).removeClass("has-val");
        }
      });
    });

    var input = $(".validate-input .input100");

    $(".validate-form").on("submit", function () {
      var check = true;

      for (var i = 0; i < input.length; i++) {
        if (validate(input[i]) == false) {
          showValidate(input[i]);
          check = false;
        }
      }
      return check;
    });

    $(".validate-form .input100").each(function () {
      $(this).focus(function () {
        hideValidate(this);
      });
    });

    function validate(input) {
      // return true
      if (
        $(input).attr("type") == "email" ||
        $(input).attr("name") == "email"
      ) {
        return true;
        if (
          $(input)
            .val()
            .trim()
            .match(
              /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/
            ) == null
        ) {
          return false;
        }
      } else {
        if ($(input).val().trim() == "") {
          return false;
        }
      }
    }

    function showValidate(input) {
      var thisAlert = $(input).parent();
      $(thisAlert).addClass("alert-validate");
    }

    function hideValidate(input) {
      var thisAlert = $(input).parent();
      $(thisAlert).removeClass("alert-validate");
    }

    var showPass = 0;
    $(".btn-show-pass").on("click", function () {
      if (showPass == 0) {
        $(this).next("input").attr("type", "text");
        $(this).find("i").removeClass("zmdi-eye");
        $(this).find("i").addClass("zmdi-eye-off");
        showPass = 1;
      } else {
        $(this).next("input").attr("type", "password");
        $(this).find("i").addClass("zmdi-eye");
        $(this).find("i").removeClass("zmdi-eye-off");
        showPass = 0;
      }
    });
  }

  numberValidate(event: any) {
    event.target.value = event.target.value.replace(/[^0-9]*/g, "");
  }
  nameValidate(event: any) {
    event.target.value = event.target.value.replace(/[^A-Za-z. ]*/g, "");
  }

  closeModel() {
    this.isLoginDetailsSubmitted = false;
  }

  companyDetails() {
    const { fileUrl } = environment;
    if (sessionStorage.homepageimage_path) {
      document
        .getElementById("homepageimage_path")
        ?.setAttribute(
          "src",
          `${fileUrl}${sessionStorage.homepageimage_path} `
        );
    }
  }

  getCompanyDetails() {
    const len: number = sessionStorage.length;
    if (len == 0) sessionStorage.isDomain = false;
    const { fileUrl } = environment;
    let { hostname } = location;
    if (["localhost", "shiksion.com"].includes(hostname)) return;
    if (len == 0) {
      this.CommonService.getCall(
        `account/IsSubDomainExists/${hostname}`
      ).subscribe((res: any) => {
        if (res.data == true) {
          this.CommonService.getCall(
            `account/GetCompanyDetails/${hostname}`
          ).subscribe((res: any) => {
            if (res.data.length > 0) {
              sessionStorage.isDomain = true;
              sessionStorage.company_id = res.data[0].company_id;
              if (res.data[0].cerficateimage_path)
                sessionStorage.cerficateimage_path =
                  res.data[0].cerficateimage_path;
              if (res.data[0].favicon_path)
                sessionStorage.favicon_path = res.data[0].favicon_path;
              if (res.data[0].homepageimage_path)
                sessionStorage.homepageimage_path =
                  res.data[0].homepageimage_path;
              if (res.data[0].landingpageimage_path)
                sessionStorage.landingpageimage_path =
                  res.data[0].landingpageimage_path;
              if (sessionStorage.favicon_path) {
                document
                  .getElementById("appFavcon")
                  ?.setAttribute(
                    "href",
                    `${fileUrl}${res.data[0].favicon_path}`
                  );
              }
              if (sessionStorage.homepageimage_path) {
                document
                  .getElementById("homepageimage_path")
                  ?.setAttribute(
                    "src",
                    `${fileUrl}${sessionStorage.homepageimage_path} `
                  );
              }

              // document.getElementById("homepageimage_path")
              // console.log("AppComponent");
            }
          });
        }
      });
    }
  }

  getCompanyList() {
    this.CommonService.getCall(`Registration/GetCompanyList`).subscribe(
      (res: any) => {
        this.companyList = res.data;
        // console.log(this.companyList);
      }
    );
  }
}
