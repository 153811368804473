<!-- -----------------------NEW UI--------------------------------- -->

<div class="bg-white">
  <div class="container-fluid">
    <div class="row login-card">
      <div
        class="col-lg-6 d-flex align-items-center justify-content-center"
        style="background-color: #fff"
      >
        <div class="row">
          <div class="col-12">
            <div class="card border-0">
              <div
                class="card-body px-md-4 py-5"
                [formGroup]="loginForm"
                style="cursor: default"
              >
                <div class="text-center">
                  <a href="javascript:" style="cursor: default">
                    <!-- logo1-new.png tivitlogo.png-->
                    <img
                      src="../../../assets/img/logo1-new.png"
                      class="img-fluid"
                      style="max-height: 90px; width: auto"
                      alt=""
                    />
                    <!-- <img src="../../../assets/img/logo2-new.png" class="img-fluid"
                                    style="max-height: 90px; width: auto" alt=""> -->
                  </a>
                </div>
                <h6 class="text-center mt-4">
                  Welcome to Open University of Kenya
                </h6>
                <!-- <h6 class="text-center mt-4">Welcome to Dhanush</h6> -->

                <h5 class="text-center my-4">Login</h5>
                <form
                  class="form"
                  [formGroup]="loginForm"
                  (keydown.enter)="registerUser()"
                >
                  <div class="mt-3">
                    <div
                      class="form-floating mb-3"
                      data-validate="Valid email is: mailto:a@b.c"
                    >
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder=""
                        formControlName="userName"
                        [ngClass]="{
                          'is-invalid': submitted && f.userName.errors
                        }"
                      />
                      <label for="floatingInput">Email address</label>
                      <div
                        *ngIf="submitted && f.userName.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.userName.errors.required">
                          Email Id is required
                        </div>
                      </div>
                    </div>

                    <div class="form-floating" data-validate="Enter password">
                      <span class="btn-show-pass">
                        <!-- <i class="zmdi zmdi-eye"></i> --><i
                          class="fa"
                          id="viewPassword"
                          [ngClass]="{
                            'fa-eye-slash': !fieldTextType,
                            'fa-eye': fieldTextType
                          }"
                          (click)="toggleFieldTextType()"
                        ></i
                      ></span>
                      <input
                        type="password"
                        class="form-control"
                        id="floatingPassword"
                        placeholder=""
                        formControlName="password"
                        [type]="fieldTextType ? 'text' : 'password'"
                        [ngClass]="{
                          'is-invalid': submitted && f.password.errors
                        }"
                      />
                      <label for="floatingPassword">Password</label>
                      <div
                        *ngIf="submitted && f.password.errors"
                        class="invalid-feedback"
                      >
                        <div *ngIf="f.password.errors.required">
                          Password is required
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <div class="mt-3 d-none">
                  <input
                    type="text"
                    class="form-control mb-2"
                    placeholder="User Name"
                  />
                  <input
                    type="text"
                    class="form-control mb-2"
                    placeholder="Password"
                  />
                </div>

                <!-- <a class="btn btn-save  d-block fw-500 mt-4" value="login" (click)="registerUser(loginForm)">Login</a> -->
                <a
                  class="btn btm-md btn-primary lgn-btn d-block fw-500 mt-4 py-2"
                  value="login"
                  (click)="registerUser()"
                  >Login</a
                >
              </div>
              <div class="container-login100-form-btn">
                <!-- (click)="onForgotPasswordClick()" -->
                <span
                  >Forgot password?
                  <a
                    style="cursor: pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    ><u>Click Here</u></a
                  ></span
                >
                <!-- <span>Don't have an account? <a  style="cursor:pointer" (click)="gotoSignUp()" ><u>SignUp</u></a></span> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-lg-6 d-flex align-items-center justify-content-center text-white"
        style="background-color: #037b90"
      >
        <div class="row">
          <div class="col-12">
            <div
              class="card border-0 bg-transparent border-0 justify-content-center"
            >
              <div class="card-body px-md-5 py-5">
                <h5 class="text-center">Welcome!</h5>
                <h5 class="text-center mt-4">Don't have an account?</h5>
                <br />
                <br />
                <br />
                <a
                  (click)="gotoSignUp()"
                  class="btn btm-md btn-primary lgn-btn d-block fw-500 mt-4 py-2"
                  >Signup</a
                ><br />
                <!-- <p style="color: white;">KUCCPS Students: <a href="" (click)="ChangeUrl()"> <span
                                            style="color: black;text-decoration: underline;">Click here</span> </a></p> -->
                <!-- <p style="color: white;">Staff login: <a href="" (click)="ChangeUrl()"> <span
                                            style="color: black;text-decoration: underline;">Click here</span> </a></p> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Forgot Password</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="row mb-2" style="align-items: center">
              <div class="col-md-3">
                Email Id
                <span class="text-danger" style="font-size: 20px">* :</span>
              </div>
              <div class="col-md-9 pt-2">
                <input
                  type="email"
                  class="form-control"
                  (blur)="validateEmail($event)"
                  [(ngModel)]="emailId"
                  [ngModelOptions]="{ standalone: true }"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-save"
          id="md_close"
          (click)="close()"
          data-bs-dismiss="modal"
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-save"
          (click)="onForgotPasswordClick()"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</div>
