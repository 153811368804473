<div class="limiter">
  <div
    class="container-login100"
    style="background-image: url('../../../assets/images/dhanush/bg-image.png')"
  >
    <div class="wrap-login100" style="padding: 22px 55px 24px 55px">
      <!-- <div class="text-center"> <img id="homepageimage_path" src="../../../../assets/img/samvaad_tutor_logo.png" [routerLink]="['']"
                    style="cursor:pointer;" class="img-fluid" />
            </div> -->
      <br />
      <div class="container-fluid container-login100">
        <div class="row">
          <div class="col-xl-8 mx-xl-auto px-4 py-5">
            <div class="card card1 mb-2">
              <div class="card-header text-center">
                <h5 class="mb-0">Application Form</h5>
              </div>
              <div class="card-body px-4">
                <div class="row">
                  <div class="col-12">
                    <div class="text-center">
                      <img
                        src="../../../assets/img/ouk-logo-new.png"
                        alt="oukLogo"
                        style="width: 30%; height: auto"
                      />
                    </div>
                    <p class="text-danger text-center mb-0 pt-3">
                      * Indicates required Fields
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="card card1 mb-3" *ngIf="signupCard">
              <div class="card-body px-4">
                <form action="" [formGroup]="signUpForm" (ngOnSubmit)="save()">
                  <div class="row">
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >First Name<span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="FirstName"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Last Name<span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="LastName"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Middle Name</label
                      >
                      <input
                        type="text"
                        formControlName="middle_name"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >ID/Passport<span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        formControlName="id_passport"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Phone Number <span class="text-danger">*</span></label
                      >
                      <input
                        type="number"
                        formControlName="Mobileno"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Email<span class="text-danger">*</span></label
                      >
                      <input
                        type="email"
                        formControlName="UserName"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Gender<span class="text-danger">*</span></label
                      >
                      <select formControlName="Gender" class="form-control">
                        <option value="">Select</option>
                        <option
                          *ngFor="let item of gender"
                          [value]="item.DICTIONARYID"
                        >
                          {{ item.DICTIONARYNAME }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Country<span class="text-danger">*</span></label
                      >
                      <select formControlName="country_id" class="form-control">
                        <option value="">Select</option>
                        <option
                          *ngFor="let item of addressCountry"
                          [value]="item.DICTIONARYID"
                        >
                          {{ item.DICTIONARYNAME }}
                        </option>
                      </select>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Specify Highest Qualification<span class="text-danger"
                          >*</span
                        ></label
                      >
                      <select
                        formControlName="specify_highest_qualification"
                        class="form-control"
                      >
                        <option value="">Select</option>
                        <option value="1">Post Graduate</option>
                        <option value="2">Degree</option>
                        <option value="3">Diploma</option>
                        <option value="4">Certificate</option>
                        <option value="5">KCSE</option>
                      </select>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Programme Category<span class="text-danger"
                          >*</span
                        ></label
                      >
                      <select
                        formControlName="programme_category_id"
                        class="form-control"
                      >
                        <option value="">Select</option>
                        <option value="1">Post Graduate</option>
                        <option value="2">Degree</option>
                        <option value="3">Diploma</option>
                        <option value="4">Certificate</option>
                      </select>
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Programme Interested In<span class="text-danger"
                          >*</span
                        ></label
                      >
                      <select
                        formControlName="course_interested_in"
                        class="form-control"
                      >
                        <option value="">Select</option>
                        <option value="1">Bachelor Of Data Science</option>
                        <option value="2">
                          Bachelor Of Cyber Security And Digital Forensics
                        </option>
                        <option value="3">
                          Bachelor Of Technology Education
                        </option>
                        <option value="4">
                          Bachelor Of Bussiness And Enterpreneurship
                        </option>
                        <option value="5">
                          Bachelor Of Economics And Statistics
                        </option>
                        <option value="6">
                          Bachelor Of AgriTechnology and Food Systems
                        </option>
                        <option value="7">
                          Postgraduate Diploma in Learning Design and Technology
                        </option>
                        <option value="8">
                          Postgraduate Diploma in Leadership
                        </option>
                      </select>
                    </div>

                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <!-- <span class="text-danger">*</span> -->
                      <label for="textfiled1" class="form-label mb-1"
                        >High School Certificate
                      </label>
                      <input
                        type="file"
                        formControlName="upload_1"
                        (change)="changeFile($event, 1)"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >High School Transcript
                      </label>
                      <input
                        type="file"
                        formControlName="upload_2"
                        (change)="changeFile($event, 2)"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >National ID/Passport
                      </label>
                      <input
                        type="file"
                        formControlName="upload_3"
                        (change)="changeFile($event, 3)"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Passport Size Photograph
                      </label>
                      <input
                        type="file"
                        formControlName="upload_4"
                        (change)="changeFile($event, 4)"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Application Essay
                      </label>
                      <input
                        type="file"
                        formControlName="upload_5"
                        (change)="changeFile($event, 5)"
                        class="form-control"
                        id="textfiled1"
                        aria-describedby="emailHelp"
                      />
                    </div>
                    <div class="col-xl-6 col-md-6 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1"
                        >Comment</label
                      >
                      <textarea
                        formControlName="comment"
                        cols="20"
                        rows="5"
                        class="form-control"
                      ></textarea>
                      <!-- <input type="number" class="form-control  " id="textfiled1" aria-describedby="emailHelp"> -->
                    </div>
                  </div>
                </form>
                <div class="row">
                  <div class="col-12">
                    <button
                      class="btn btn-primary float-end px-3 ms-3"
                      (click)="nextSlide(); save(signUpForm)"
                      [disabled]="signUpForm.invalid"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card1 mb-3" *ngIf="questionsCard">
              <div class="card card1 mb-3">
                <div class="card-body px-4">
                  <div class="row" *ngFor="let item of questionsList">
                    <div class="col-xl-12 col-12 mb-3">
                      <label for="textfiled1" class="form-label mb-1">
                        {{ item.REG_QUESTION_NAME }}
                        <!-- Do you have any exams in the
                                                upcoming month?/क्या आने वाले महीने में आपकी कोई परीक्षा है? --> </label
                      ><br />
                      <ng-container *ngIf="item.REG_QUESTION_TYPE === 1">
                        <ng-container
                          *ngFor="
                            let ques of item?.dlc_registration_question_answer
                          "
                        >
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="radio"
                              [id]="
                                'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID
                              "
                              [name]="'question' + item.REG_QUESTION_ID"
                              [value]="ques.REG_QUESTION_ANSWER_ID"
                              [checked]="
                                ques.REG_QUESTION_ANSWER_ID ===
                                ques.SelectedOption
                              "
                              (change)="
                                onAnswerSelected(
                                  item.REG_QUESTION_ID,
                                  ques.REG_QUESTION_ANSWER_ID,
                                  ques?.REG_QUESTION_ANSWER_NAME,
                                  item.REG_QUESTION_TYPE
                                )
                              "
                            />
                            <input type="text" *ngIf="inputFld" />
                            <label
                              class="form-check-label"
                              [for]="
                                'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID
                              "
                              >{{ ques?.REG_QUESTION_ANSWER_NAME }}</label
                            >
                          </div>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="item.REG_QUESTION_TYPE === 2">
                        <div class="row">
                          <div class="col-md-6">
                            <input
                              type="text"
                              class="form-control"
                              id="textfiled1"
                              aria-describedby="emailHelp"
                              (input)="
                                onInputValueChanged(
                                  item.REG_QUESTION_ID,
                                  $event.target.value,
                                  item.REG_QUESTION_TYPE
                                )
                              "
                            />
                          </div>
                        </div>
                      </ng-container>

                      <ng-container *ngIf="item.REG_QUESTION_TYPE === 3">
                        <!-- For questions with checkboxes (REG_QUESTION_TYPE = 3) -->
                        <ng-container
                          *ngFor="
                            let ques of item?.dlc_registration_question_answer
                          "
                        >
                          <div class="form-check form-check-inline">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              [id]="
                                'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID
                              "
                              [name]="'question' + item.REG_QUESTION_ID"
                              [value]="ques.REG_QUESTION_ANSWER_ID"
                              [checked]="
                                ques.SelectedOption?.includes(
                                  ques?.REG_QUESTION_ANSWER_ID
                                )
                              "
                              (change)="
                                onCheckboxValueChanged(
                                  item.REG_QUESTION_ID,
                                  ques.REG_QUESTION_ANSWER_ID,
                                  item.REG_QUESTION_TYPE
                                )
                              "
                            />
                            <label
                              class="form-check-label"
                              [for]="
                                'inlineCheckbox' + ques.REG_QUESTION_ANSWER_ID
                              "
                              >{{ ques.REG_QUESTION_ANSWER_NAME }}</label
                            >
                          </div>
                        </ng-container>
                      </ng-container>

                      <!-- Question ID 4: File Upload -->
                      <ng-container *ngIf="item.REG_QUESTION_TYPE === 4">
                        <input
                          type="file"
                          id="fileUpload"
                          (change)="
                            onFileUploadChanged(
                              $event,
                              item.REG_QUESTION_ID,
                              1,
                              item.REG_QUESTION_TYPE
                            )
                          "
                        />
                        <ng-container
                          *ngFor="
                            let ques of item?.dlc_registration_question_answer
                          "
                        >
                          <label for="fileUpload">{{
                            ques.REG_QUESTION_ANSWER_NAME
                          }}</label>
                        </ng-container>
                        <span *ngIf="imgFIle != ''">{{ imgFIle }}</span>
                      </ng-container>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <button
                        class="btn btn-primary float-end px-3 ms-3"
                        (click)="submitAnswers()"
                      >
                        Submit
                      </button>
                      <button
                        class="btn btn-outline-primary float-end px-3"
                        [disabled]="backBtn"
                        (click)="back()"
                      >
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-student-address *ngIf="isLoginDetailsSubmitted" (addressEvent)="getAddressDetails($event)"></app-student-address> -->
  </div>
</div>
