<div class="wrapper">
  <div class="page-wrapper pt-5">
    <div class="page-content pt-0 pe-2 ps-2">
      <div class="container-fluid">
        <div class="default_class">
          <div class="content-wrapper p-0">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-12">
                  <div class="container">
                    <div class="course-box">
                      <div class="course-header">
                        <img
                          class="course-image"
                          [src]="
                            courseDetails.IMAGE_URL == '' ||
                            courseDetails.IMAGE_URL == undefined
                              ? '../../../assets/img/subject-img.jpg'
                              : courseDetails.IMAGE_URL
                          "
                          alt="Course Image"
                        />
                        <h4 class="course-title">
                          {{ courseDetails.COURSE_NAME }}
                        </h4>
                        <button class="apply-btn" (click)="openModal1()">
                          Apply Now
                        </button>
                      </div>

                      <p class="course-description">
                        {{ courseDetails.COURSE_DESCRIPTION }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
