<div class="wrapper">
  <div class="page-wrapper pt-4">
    <div class="page-content pt-0 pe-2 ps-2">
      <div class="container-fluid">
        <div class="default_class">
          <div class="content-wrapper p-0">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-12">
                  <div class="card rounded shadow-lg">
                    <div class="container-fluid">
                      <div class="row">
                        <div class="col-xl-8 pe-lg-4 ps-lg-1">
                          <div class="pt-3 pb-3">
                            <nav aria-label="breadcrumb">
                              <ol class="breadcrumb mb-0 p-0 d-none">
                                <li class="breadcrumb-item">
                                  <a href="javascript:;"
                                    ><i class="bx bx-home-alt"></i
                                  ></a>
                                </li>
                                <li
                                  class="breadcrumb-item active"
                                  style="font-size: 15px"
                                  aria-current="page"
                                >
                                  Dashboard
                                </li>
                              </ol>
                            </nav>
                          </div>

                          <div class="row">
                            <div class="col-xl-6 mb-4">
                              <div class="card card-1 card-1 card-shadow">
                                <div class="card-body" style="color: #172985">
                                  <h5 class="float-start">Registered Units</h5>
                                  <h5 class="float-end">2344</h5>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-6 mb-4">
                              <div class="card card-1 card-1 card-shadow">
                                <div class="card-body" style="color: #172985">
                                  <h5 class="float-start">Attempted Units</h5>
                                  <h5 class="float-end">2344</h5>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-6 mb-4">
                              <div class="card card-1 card-1 card-shadow">
                                <div class="card-header border-0">
                                  <h5 style="color: #172985; font-size: 16px">
                                    Notice
                                  </h5>
                                </div>
                                <div
                                  class="card-body smooth-scroll table-style px-0 pt-0 style-1"
                                >
                                  <table class="table table-borderless">
                                    <tr class="bg-light">
                                      <td class="title" colspan="2">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td colspan="2">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr>
                                      <td colspan="2">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td colspan="2">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-6 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-header border-0">
                                  <h5>Ongoing Sessions</h5>
                                </div>
                                <div
                                  class="card-body smooth-scroll table-style px-0 pt-0 style-1"
                                >
                                  <table class="table table-borderless">
                                    <tr class="bg-light">
                                      <td colspan="3">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="sub-title" colspan="2">
                                        Computing Class
                                      </td>
                                      <td rowspan="2" class="pt-2">
                                        <button class="btn btn-primary">
                                          Join Class
                                        </button>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>

                                    <tr>
                                      <td class="title" colspan="3">
                                        <p class="title">
                                          Class name with the extra length text
                                          will be like Class name with the extra
                                          length text will be like
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="sub-title" colspan="2">
                                        Computing Class
                                      </td>
                                      <td rowspan="2" class="pt-2">
                                        <button class="btn btn-primary">
                                          Join Class
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td colspan="3">
                                        <p class="title">
                                          No online classes today
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="sub-title" colspan="2">
                                        Computing Class
                                      </td>
                                      <td rowspan="2" class="pt-2">
                                        <button class="btn btn-primary">
                                          Join Class
                                        </button>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr>
                                      <td class="title" colspan="3">
                                        <p class="title">
                                          Class name with the extra length text
                                          will be like Class name with the extra
                                          length text will be like
                                        </p>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="sub-title" colspan="2">
                                        Computing Class
                                      </td>
                                      <td rowspan="2" class="pt-2">
                                        <button class="btn btn-primary">
                                          Join Class
                                        </button>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="title" colspan="3">
                                        <p class="title">
                                          Class name with the extra length text
                                          will be like Class name with the extra
                                          length text will be like
                                        </p>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="sub-title" colspan="2">
                                        Computing Class
                                      </td>
                                      <td rowspan="2" class="pt-2">
                                        <button class="btn btn-primary">
                                          Join Class
                                        </button>
                                      </td>
                                    </tr>
                                    <tr class="bg-light">
                                      <td class="dt-time">15-04-2022</td>
                                      <td class="dt-time">09:00 AM</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-xl-12 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-header border-0">
                                  <h5>Fee Payment</h5>
                                </div>
                                <div class="card-body px-2 pt-0">
                                  <div class="table-responsive">
                                    <table class="table table-borderless">
                                      <tr>
                                        <td>No online classes today</td>
                                      </tr>
                                      <tr>
                                        <td>No online classes today</td>
                                      </tr>
                                      <tr>
                                        <td>No online classes today</td>
                                      </tr>
                                    </table>
                                  </div>
                                </div>
                                <div class="card-footer">
                                  <button class="btn btn-success me-2">
                                    Make Payment</button
                                  ><a href="#"> <span>Already Paid ?</span></a>
                                </div>
                              </div>
                            </div>
                            <div class="col-xl-12 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-header border-0">
                                  <h5>Assesment</h5>
                                </div>
                                <div class="card-body table-style px-0 pt-0">
                                  <div class="table-responsive">
                                    <table class="table table-borderless">
                                      <tr class="bg-light">
                                        <td>
                                          <p class="title">
                                            No online classes today
                                          </p>
                                        </td>
                                        <td rowspan="2">
                                          <button
                                            class="btn btn-outline-primary float-end mt-3"
                                          >
                                            View Files
                                          </button>
                                        </td>
                                        <td rowspan="2">
                                          <button class="btn btn-primary mt-3">
                                            Start Test
                                          </button>
                                        </td>
                                      </tr>
                                      <tr class="bg-light">
                                        <td class="sub-title">
                                          Computing Class
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>
                                          <p class="title">
                                            No online classes today
                                          </p>
                                        </td>
                                        <td rowspan="2">
                                          <button
                                            class="btn btn-outline-primary float-end mt-3"
                                          >
                                            View Files
                                          </button>
                                        </td>
                                        <td rowspan="2">
                                          <button class="btn btn-primary mt-3">
                                            Start Test
                                          </button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td class="sub-title">
                                          Computing Class
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                  <div class="text-center">
                                    <a
                                      href="#"
                                      style="
                                        text-decoration: none;
                                        color: #9c9c9d;
                                        font-size: 13px;
                                      "
                                      >View all
                                      <i
                                        class="fas fa-long-arrow-alt-right ms-2"
                                      ></i
                                    ></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-xl-12 mb-4">
                              <div class="card card-1 card-shadow">
                                <div class="card-body table-style">
                                  <nav class="tabs-style">
                                    <div class="float-start pt-2">
                                      <h5>My Courses</h5>
                                    </div>
                                    <div
                                      class="nav nav-tabs border-0 float-end"
                                      id="nav-tab"
                                      role="tablist"
                                    >
                                      <button
                                        class="nav-link active border-0"
                                        id="nav-all-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-all"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-all"
                                        aria-selected="true"
                                      >
                                        All
                                      </button>
                                      <button
                                        class="nav-link border-0"
                                        id="nav-ongoing-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-ongoing"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-ongoing"
                                        aria-selected="false"
                                      >
                                        Ongoing
                                      </button>
                                      <button
                                        class="nav-link border-0"
                                        id="nav-meetings-tab"
                                        data-bs-toggle="tab"
                                        data-bs-target="#nav-meetings"
                                        type="button"
                                        role="tab"
                                        aria-controls="nav-meetings"
                                        aria-selected="false"
                                      >
                                        Compeleted
                                      </button>
                                    </div>
                                  </nav>
                                  <div
                                    class="tab-content mt-5"
                                    id="nav-tabContent"
                                  >
                                    <div
                                      class="tab-pane fade show active"
                                      id="nav-all"
                                      role="tabpanel"
                                      aria-labelledby="nav-all-tab"
                                    >
                                      <div class="table-responsive">
                                        <table
                                          class="table table-borderless table-1"
                                        >
                                          <tr>
                                            <td>
                                              <img
                                                src="./images/video.png"
                                                class="img-fluid pt-3"
                                              />
                                            </td>
                                            <td width="30%">
                                              <p
                                                class="title"
                                                style="margin: 0px !important "
                                              >
                                                No online classes today No
                                                online classes today No online
                                                classes today
                                              </p>
                                              <span class="sub-title pt-0"
                                                >Computing Class</span
                                              >
                                            </td>
                                            <td width="30%" class="pt-3">
                                              <div class="progress">
                                                <div
                                                  class="progress-bar"
                                                  role="progressbar"
                                                  style="width: 90%"
                                                  aria-valuenow="90"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                ></div>
                                              </div>
                                            </td>
                                            <td class="pt-3">
                                              <i
                                                class="fas fa-star star-icon"
                                              ></i>
                                              <span>4.3</span>
                                            </td>
                                            <td class="btn-rounded-1 pt-2">
                                              <button
                                                type="button"
                                                class="btn btn-outline-primary"
                                              >
                                                Resume Course
                                              </button>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <img
                                                src="./images/video.png"
                                                class="img-fluid pt-3"
                                              />
                                            </td>
                                            <td width="30%">
                                              <p
                                                class="title"
                                                style="margin: 0px !important "
                                              >
                                                No online classes today No
                                                online classes today No online
                                                classes today
                                              </p>
                                              <span class="sub-title pt-0"
                                                >Computing Class</span
                                              >
                                            </td>
                                            <td width="30%" class="pt-3">
                                              <div class="progress">
                                                <div
                                                  class="progress-bar"
                                                  role="progressbar"
                                                  style="width: 75%"
                                                  aria-valuenow="75"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                ></div>
                                              </div>
                                            </td>
                                            <td class="pt-3">
                                              <i
                                                class="fas fa-star star-icon"
                                              ></i>
                                              <span>4.3</span>
                                            </td>
                                            <td class="btn-rounded-1 pt-2">
                                              <button
                                                type="button"
                                                class="btn btn-outline-primary"
                                              >
                                                Resume Course
                                              </button>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <img
                                                src="./images/video.png"
                                                class="img-fluid pt-3"
                                              />
                                            </td>
                                            <td width="30%">
                                              <p
                                                class="title"
                                                style="margin: 0px !important "
                                              >
                                                No online classes today No
                                                online classes today No online
                                                classes today
                                              </p>
                                              <span class="sub-title pt-0"
                                                >Computing Class</span
                                              >
                                            </td>
                                            <td width="30%" class="pt-3">
                                              <div class="progress">
                                                <div
                                                  class="progress-bar"
                                                  role="progressbar"
                                                  style="width: 25%"
                                                  aria-valuenow="25"
                                                  aria-valuemin="0"
                                                  aria-valuemax="100"
                                                ></div>
                                              </div>
                                            </td>
                                            <td class="pt-3">
                                              <i
                                                class="fas fa-star star-icon"
                                              ></i>
                                              <span>4.3</span>
                                            </td>
                                            <td class="btn-rounded-1 pt-2">
                                              <button
                                                type="button"
                                                class="btn btn-outline-primary"
                                              >
                                                Resume Course
                                              </button>
                                            </td>
                                          </tr>
                                        </table>
                                        <div class="float-end">
                                          <a
                                            href="#"
                                            style="
                                              text-decoration: none;
                                              color: #0066ff;
                                              font-size: 16px;
                                              font-weight: 700;
                                              padding: 0px 25px 0px 0px;
                                            "
                                            >View all
                                            <i
                                              class="fas fa-long-arrow-alt-right ms-1"
                                            ></i
                                          ></a>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      class="tab-pane fade"
                                      id="nav-ongoing"
                                      role="tabpanel"
                                      aria-labelledby="nav-ongoing-tab"
                                    >
                                      45
                                    </div>
                                    <div
                                      class="tab-pane fade"
                                      id="nav-meetings"
                                      role="tabpanel"
                                      aria-labelledby="nav-meetings-tab"
                                    >
                                      89
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-xl-4 bg-white">
                          <div class="card card-1 card-shadow">
                            <div class="card-header border-0">
                              <h5>Basic Information</h5>
                            </div>
                            <div
                              class="card-body table-style px-0 pt-0"
                              style="font-size: 12px !important"
                            >
                              <div class="text-center">
                                <img
                                  src="https://placehold.jp/100x100.png"
                                  class="img-fluid rounded-circle"
                                />
                              </div>

                              <div
                                class=""
                                style="
                                  white-space: nowrap;
                                  font-size: 12px !important;
                                "
                              >
                                <table
                                  class="table table-borderless"
                                  style="font-size: 12px !important"
                                >
                                  <tr>
                                    <td class="text-left">Name</td>
                                    <td class="text-left">Samuel</td>
                                  </tr>
                                  <tr>
                                    <td>Admission No</td>
                                    <td>Bus-2332424-2320-234</td>
                                  </tr>
                                  <tr>
                                    <td>ID/Passport</td>
                                    <td>3453465785467</td>
                                  </tr>

                                  <tr>
                                    <td>Gender</td>
                                    <td>Male</td>
                                  </tr>
                                  <tr>
                                    <td>Date of Birth</td>
                                    <td>12-03-1999</td>
                                  </tr>

                                  <tr>
                                    <td colspan="2">
                                      <b>Contact Information</b>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Phone Number</td>
                                    <td>9435465789</td>
                                  </tr>
                                  <tr>
                                    <td>Email</td>
                                    <td>Mail@gmail.com</td>
                                  </tr>
                                  <tr>
                                    <td>City</td>
                                    <td>Nirobi</td>
                                  </tr>
                                  <tr>
                                    <td>Postal Address</td>
                                    <td>45364756</td>
                                  </tr>
                                  <tr>
                                    <td>Home Address</td>
                                    <td>
                                      6<sup>th</sup>floor, lakshmis building,<br />
                                      Begumpet,hyderabad
                                    </td>
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div>

                          <div class="app">
                            <div class="app__main shadow-sm mt-3">
                              <div class="calendar">
                                <div id="calendar"></div>
                              </div>
                            </div>
                          </div>
                          <div class="upcoming mt-3">
                            <h6>Upcoming</h6>
                            <p>18-03-2022</p>

                            <ul>
                              <li>DBMS Assignment</li>
                              <li>Cyber Law class</li>
                            </ul>
                          </div>

                          <div class="card card-1 card-shadow right-cards mb-3">
                            <div class="card-body">
                              <div class="text-center">
                                <h6>What sessions would you like to prefer?</h6>
                                <div class="btn-rounded-1 mt-4">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary px-4 py-1 me-2"
                                  >
                                    Online
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary px-4 py-1"
                                  >
                                    Offline
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="card card-1 card-shadow right-cards mb-3">
                            <div class="card-body">
                              <div class="text-center">
                                <h6>What sessions would you like to prefer?</h6>
                                <div class="btn-rounded-1 mt-4">
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary px-4 py-1 me-2"
                                  >
                                    Online
                                  </button>
                                  <button
                                    type="button"
                                    class="btn btn-outline-primary px-4 py-1"
                                  >
                                    Offline
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
