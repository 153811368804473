<div
  class="wrapper"
  style="padding-top: 5.5rem; padding-left: 30px; padding-bottom: 30px"
>
  <div class="container-fluid">
    <div class="p-2">
      <div class="row">
        <div class="col-xl-2"></div>
        <div class="col-xl-10">
          <div class="row">
            <!-- <div class="col-xl-2"></div> -->
            <div class="col-lg-9">
              <div class="d-flex flex-wrap gap-3 w-100">
                <div class="flex-item">
                  <div class="card cst_1">
                    <div class="card-body">
                      <div class="">
                        <div class="d-flex justify-content-between mb-2">
                          <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                          <div class="">
                            <h6
                              class="mb-2"
                              style="font-weight: 600; font-size: 14px"
                            >
                              Admitted
                            </h6>
                            <h5 class="mb-0">
                              <a
                                href="javascript:void()"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(
                                    4,
                                    'Admitted',
                                    admitted?.course_id
                                  )
                                "
                                >{{ admittedCount?.COUNT1 }}</a
                              >
                            </h5>
                          </div>
                          <div class="mt-auto"></div>
                        </div>
                        <div class="mb-2">
                          <span class="badge bg-light text-dark">
                            In Review:
                            <a
                              href="javascript:void()"
                              data-bs-target="#count"
                              data-bs-toggle="modal"
                              (click)="
                                registrationDetails(
                                  5,
                                  'In Review',
                                  in_review?.course_id
                                )
                              "
                              >{{ admittedCount?.REVIEWCOUNT }}</a
                            >
                          </span>
                        </div>
                        <p style="font-size: 11px !important">
                          Current admitted student status
                        </p>
                        <div class="d-flex justify-content-between mb-2">
                          <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                          <div class="">
                            <span class="badge bg-light text-danger">
                              Rejected:
                              <a
                                href="javascript:void();"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(
                                    6,
                                    'Rejected',
                                    rejected?.course_id
                                  )
                                "
                              >
                                {{ admittedCount?.REJECTEDCOUNT }}</a
                              >
                            </span>
                          </div>
                          <div class="mt-auto">
                            <!-- <span class="badge bg-light text-danger">
                                  <i class="bi bi-arrow-down"></i>
                                </span> -->
                            <!-- <span class="badge bg-light text-danger">
                                 <a href="javascript:void();"  data-bs-target="#count" data-bs-toggle="modal" (click)="registrationDetails(6,'Rejected',rejected?.course_id)"><i class="fas fa-eye"></i></a> 
                                </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-item">
                  <div class="card cst_1">
                    <div class="card-body">
                      <div class="">
                        <div class="d-flex justify-content-between mb-2">
                          <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                          <div class="">
                            <h6
                              class="mb-2"
                              style="font-weight: 600; font-size: 14px"
                            >
                              Registered
                            </h6>
                            <h5 class="mb-0">
                              <a
                                href="javascript:void()"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(
                                    13,
                                    'Enroled',
                                    enroll?.course_id
                                  )
                                "
                                >{{ registeredCount?.COUNT1 }}</a
                              >
                            </h5>
                          </div>
                          <div class="mt-auto"></div>
                        </div>
                        <div class="mb-2">
                          <span class="badge bg-light text-dark">
                            In Review:
                            <a
                              href="javascript:void()"
                              data-bs-target="#count"
                              data-bs-toggle="modal"
                              (click)="
                                registrationDetails(
                                  15,
                                  'In Review',
                                  sentBack?.course_id
                                )
                              "
                              >{{ registeredCount?.REVIEWCOUNT }}</a
                            >
                          </span>
                        </div>
                        <p style="font-size: 11px !important">
                          Current registered student status
                        </p>
                        <div class="d-flex justify-content-between mb-2">
                          <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                          <div class="">
                            <span class="badge bg-light text-success">
                              Accepted:
                              <a
                                href="javascript:void();"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(
                                    14,
                                    'Accepted',
                                    notEnroll?.course_id
                                  )
                                "
                              >
                                {{ registeredCount?.REJECTEDCOUNT }}</a
                              >
                            </span>
                          </div>
                          <div class="mt-auto">
                            <!-- <span class="badge bg-light text-danger">
                                  <i class="bi bi-arrow-down"></i>
                                </span> -->
                            <!-- <span class="badge bg-light text-danger">
                                  <a href=""><i class="fas fa-eye"></i></a> </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-item">
                  <div class="card cst_1">
                    <div class="card-body">
                      <div class="">
                        <h6
                          class="mb-2"
                          style="font-weight: 600; font-size: 14px"
                        >
                          Applications
                        </h6>
                        <div class="d-flex justify-content-between mb-2">
                          <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->

                          <div class="">
                            <h5 class="mb-0 me-4">
                              <a
                                href="javascript:void()"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(
                                    2,
                                    'Applied',
                                    applied?.course_id
                                  )
                                "
                                >{{ appliedCount?.COUNT1 }}</a
                              >
                            </h5>
                          </div>
                          <div class="mt-auto">
                            <span class="badge bg-light text-dark">
                              applied
                            </span>

                            <!-- <span class="badge bg-light text-danger">
                                  <i class="bi bi-graph-up-arrow"></i>
                                </span> -->
                          </div>
                        </div>
                        <div class="mb-2">
                          <span class="badge bg-light text-dark">
                            In Review:<a
                              href="javascript:void()"
                              data-bs-target="#count"
                              data-bs-toggle="modal"
                              (click)="
                                registrationDetails(
                                  3,
                                  'Application in Progress',
                                  applicationInPorgrs?.course_id
                                )
                              "
                            >
                              {{ appliedCount?.REVIEWCOUNT }}</a
                            >
                          </span>
                        </div>
                        <p style="font-size: 11px !important">
                          Current applied student status
                        </p>
                        <div class="d-flex justify-content-between mb-2">
                          <!-- <span class="bage rounded-circle align-items-center d-flex" style="width: 50px; height: 50px; background-color: #EFEFEF;"> <i class="bi bi-people-fill icon-style "style="font-size:20px; margin:0px auto;"></i></span> -->
                          <div class="">
                            <span class="badge bg_light_cstm">
                              Interested :
                              <a
                                href="javascript:void();"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(
                                    1,
                                    'Signed up',
                                    signedUp?.course_id
                                  )
                                "
                              >
                                {{ appliedCount?.REJECTEDCOUNT }}</a
                              >
                            </span>
                          </div>
                          <div class="mt-auto">
                            <!-- <span class="badge bg-light text-danger">
                                  <a href=""><i class="fas fa-eye">View more</i></a>                                 </span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-item" style="width: 31%">
                  <div class="card cst_1">
                    <div class="card-body">
                      <div class="">
                        <h6
                          class="mb-2"
                          style="font-weight: 600; font-size: 14px"
                        >
                          Alumni
                        </h6>
                        <div class="d-flex justify-content-between mb-2">
                          <div class="">
                            <h5 class="mb-0 me-4">
                              <a
                                href="javascript:void()"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="registrationDetails(17, 'Alumni', 0)"
                                >{{ aluminiCount?.COUNT1 }}</a
                              >
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex-item" style="width: 31%">
                  <div class="card cst_1">
                    <div class="card-body">
                      <div class="">
                        <h6
                          class="mb-2"
                          style="font-weight: 600; font-size: 14px"
                        >
                          Graduation
                        </h6>
                        <div class="d-flex justify-content-between mb-2">
                          <div class="">
                            <h5 class="mb-0 me-4">
                              <a
                                href="javascript:void()"
                                data-bs-target="#count"
                                data-bs-toggle="modal"
                                (click)="
                                  registrationDetails(18, 'Graduation', 0)
                                "
                                >{{ graduationCount?.COUNT1 }}</a
                              >
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-grid pb-2 d-md-flex justify-content-md-end">
                <span
                  class="badge bg-light text-danger"
                  style="
                    height: 14px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <a href="/HOME/dashboard" style="text-decoration: none">
                    <i
                      class="fas fa-eye"
                      style="color: #ff7f50; font-size: 14px"
                      >View more</i
                    >
                  </a>
                </span>
              </div>

              <div
                class="row"
                *ngIf="roleId == 1 || (DEPARTMENT_ID == '0' && SchoolId != '0')"
              >
                <div class="col-xl-12 mb-3">
                  <div class="card border-0">
                    <div class="card-body">
                      <div class="d-flex align-items-center">
                        <span
                          class="card_title h6 float-start pt-1 mb-3 fw-bold"
                          >Schools</span
                        >
                      </div>
                      <small style="color: grey; font-size: 14px"
                        >Graphical presentation of student enro Iment status
                        across various schools, displaying the numbers of
                        students who have registered, applied and been
                        admitted</small
                      >
                      <div class="row">
                        <div class="col-lg-12 text-center">
                          <canvas
                            baseChart
                            [datasets]="barChartData"
                            [labels]="barChartLabels"
                            [options]="barChartOptions"
                            [chartType]="barChartType"
                            [legend]="true"
                          >
                          </canvas>

                          <!-- <img src="../../../../assets/images/admin-dean-dashboard/bar_chart.jpg" class="img-fluid"> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-5 mb-3">
                  <div class="card cst_1 h-100">
                    <div class="card-body">
                      <div class="d-flex mb-3 align-items-center">
                        <span
                          class="card_title h6 float-start pt-1 mb-0 fw-bold"
                          >Placement</span
                        >
                      </div>
                      <!-- <div id="piechart" style="width: 100%; height: 200px;"></div> -->
                      <canvas
                        baseChart
                        [data]="pieChartData"
                        [labels]="pieChartLabels"
                        [options]="pieChartOptions"
                        [colors]="pieChartColors"
                        [chartType]="pieChartType"
                      >
                      </canvas>

                      <!-- <img src="../../../../assets/images/admin-dean-dashboard/charts_2.png" class="img-fluid"> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-7 mb-3">
                  <div class="card cst_1 h-100">
                    <div class="card-body">
                      <div class="d-flex mb-3 align-items-center">
                        <span
                          class="card_title h6 float-start pt-1 mb-0 fw-bold"
                          >Inclusivity(%)</span
                        >
                      </div>
                      <!-- <div id="piechart_1" style="width: 100%; height: 200px;"></div> -->

                      <div class="row mb-3 align-items-center">
                        <div class="col-5 text-end">
                          <span class="progress-bar-label"
                            >Students with disability:</span
                          >
                        </div>
                        <div class="col-5">
                          <div class="progress">
                            <div
                              class="progress-bar bg-success"
                              role="progressbar"
                              [style.width.%]="Inclusivity?.COUNT1"
                              aria-valuenow="8"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div class="col-2">
                          <span>{{ Inclusivity?.COUNT1 }}</span>
                        </div>
                      </div>
                      <div class="row mb-3 align-items-center">
                        <div class="col-5 text-end">
                          <span class="progress-bar-label">Youth:</span>
                        </div>
                        <div class="col-5">
                          <div class="progress">
                            <div
                              class="progress-bar bg-primary"
                              role="progressbar"
                              [style.width.%]="Inclusivity?.REVIEWCOUNT"
                              aria-valuenow="8"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </div>
                        <div class="col-2">
                          <span>{{ Inclusivity?.REVIEWCOUNT }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card border-0 shadow-sm card-radius mb-3">
                <div class="card-header bg-transparent border-0">
                  <div class="card_title h6 float-start pt-1 mb-0 fw-bold">
                    Quick Launch
                  </div>
                </div>
                <div class="card-body p-2">
                  <div class="row">
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/components/enrolmentApproval/4740"
                          title="Student Registration"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_1.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/courseSetup/subject/3179"
                          title="Course Units"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_9.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3" *ngIf="roleId == 1">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/feeManagement/paymentDetails/4882"
                          title="Payment Details"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_8.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3" *ngIf="roleId == 1">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/courseSetup/cohort/4969"
                          title="Cohort Setup"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_7.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/approveUser/4462"
                          title="Application Verification"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_6.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/components/dynamicReport/4858"
                          title="Dynamic Report"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_5.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a
                          href="/HOME/registration/transferAprroval/4876"
                          title="Inter/Intra Transfer Approval"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_4.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a
                          href="javascript:void()"
                          (click)="enrouteLibrary()"
                          title="Library"
                          class="text-decoration-none text-center mx-auto"
                        >
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_3.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                    <div class="col-xl-4 col-4 mb-3">
                      <div class="d-flex align-items-center">
                        <a class="text-decoration-none text-center mx-auto">
                          <span
                            class="bage rounded-circle align-items-center d-flex mb-2 circle_icon"
                            style="width: 50px; height: 50px"
                          >
                            <img
                              src="../../../../assets/images/admin-dean-dashboard/Imag_2.png"
                              class="img-fluid"
                              style="
                                width: 30px;
                                height: 30px;
                                margin: 0px auto;
                              "
                          /></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card programmes-container bg-white p-0">
                <div
                  class="card_title h6 float-start pt-1 mb-0 fw-bold p-2 pt-3"
                >
                  Offered Programmes
                </div>

                <div class="card-body p-0">
                  <div
                    class="badge bg_success_1 text-white active-indicator m-2"
                  >
                    Active: {{ courseList.length }}
                  </div>
                  <h6
                    class="mx-2"
                    style="font-size: 14px; color: #afafb3; font-weight: 500"
                  >
                    Featured Programmes
                  </h6>
                  <span *ngIf="roleId == 1">
                    <span *ngFor="let item of courseList | slice : 0 : 5">
                      <div class="programme-item">
                        <p>{{ item.COURSE_NAME }}</p>
                        <!-- <a href="#javascript">{{item.COURSE_NAME}}</a> -->
                      </div>
                    </span>
                  </span>
                  <span *ngIf="roleId != 1">
                    <span *ngFor="let item of courseList">
                      <div class="programme-item">
                        <p>{{ item.COURSE_NAME }}</p>
                        <!-- <a href="#javascript">{{item.COURSE_NAME}}</a> -->
                      </div>
                    </span>
                  </span>
                  <!-- <div class="programme-item active">
                        <a href="#">Bachelor of Economics and Statistics</a>
                      </div>
                      <div class="programme-item">
                        <a href="#">Postgraduate Diploma in Leadership and Accountability</a>
                      </div> -->
                </div>

                <div class="p-2">
                  <a
                    href="/HOME/courseSetup/courses/22"
                    class="text-decoration-none text-danger"
                    style="font-size: 14px; font-weight: 600"
                    (click)="showAllPrograms()"
                  >
                    View All
                    <!-- <i class="bi bi-chevron-right ms-1"></i> -->
                  </a>
                </div>
              </div>

              <div class="card border-0 shadow-sm card-radius mb-3">
                <div class="card-header bg-transparent border-0">
                  <div
                    class="card_title h6 float-start pt-1 mb-0 fw-bold d-flex align-items-center"
                  >
                    <span>Announcements</span>
                  </div>
                  <div class="float-end pt-1">
                    <a
                      href="javscript:void()"
                      class="text-decoration-none text-danger"
                      data-bs-toggle="modal"
                      data-bs-target="#myModal"
                    >
                      create</a
                    >
                  </div>
                </div>
                <div class="card-body p-3 bac">
                  <div
                    class="card dashed-border-card"
                    style="background-color: #f0f7f8"
                  >
                    <div class="card-body p-2">
                      <div class="clear-fix"></div>
                      <div *ngFor="let item of newsList">
                        <p
                          class="title1 mb-1 news_data"
                          data-bs-toggle="modal"
                          data-bs-target="#newsModal"
                          (click)="getNews(item.EVENT_ID)"
                        >
                          <a href="javascipt:;" style="color: #5a5a5a">{{
                            item.EVENT_NAME
                          }}</a>
                        </p>
                        <p class="dt-time mb-0">{{ item.EVENT_DESCRIPTION }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" tabindex="-1" id="count">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">{{ headerText }}</h5>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <div style="display: flex; gap: 5px" *ngIf="responseData.length">
          <button class="btn btn-save" (click)="downloadPDF('excel')">
            EXCEL
          </button>
          <button class="btn btn-save" (click)="downloadPDF('pdf')">PDF</button>
        </div>
        <div class="table-responsive pt-4" *ngIf="responseData.length">
          <!-- [dtOptions]="dtOptions" -->
          <table
            datatable
            class="table-striped table-bordered mt-2 pt-2 table-sm small"
            style="width: 100%; white-space: nowrap"
          >
            <thead
              class="top"
              style="background-color: #624fd7; color: #ffffff"
            >
              <tr>
                <th>S.No</th>
                <th>Reference Number</th>
                <th *ngIf="txt || Admitted">Registration Number</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Program Name</th>
                <!-- <th *ngIf="!txt">Verification Status</th>
                <th  *ngIf="!txt">Application Status</th>
                <th  *ngIf="!txt">Submission Status</th> -->
                <th *ngIf="!txt">Application Status</th>
                <th *ngIf="txt">Registration Status</th>
                <th *ngIf="!txt">Applied Date</th>
                <th *ngIf="!txt">Sign Up Date</th>
                <th *ngIf="headerText == 'Admitted'">Admission Date</th>
                <th *ngIf="headerText == 'Accepted'">Acceptance Date</th>
                <th>Username</th>
                <th>Personal Email</th>
                <th>Mobile Number</th>
                <th>Date Of Birth</th>
                <th>Gender</th>
                <th>Country</th>
                <th>County</th>
                <th>Sub County</th>
                <th *ngIf="headerText == 'Initiated'">
                  Registration Initiated Date
                </th>
                <th *ngIf="headerText == 'Enroled'">
                  Registration Approved Date
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of responseData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item.REFCODE1 }}</td>
                <td *ngIf="txt || Admitted">{{ item.REGISTRATION_NO }}</td>
                <td>{{ item.FIRSTNAME1 }}</td>
                <td>{{ item.LASTNAME1 }}</td>
                <td>{{ item.COURSENAME1 }}</td>
                <!-- <td *ngIf="!txt">{{item.VERIFICATIONSTATUS1}}</td>
                <td *ngIf="!txt">{{item.APPLICATIONSTATUS1}}</td>
                <td *ngIf="!txt">{{item.SUBMITIONSTATUS1}}</td> -->
                <td *ngIf="!txt">{{ item.SUBMITIONSTATUS1 }}</td>
                <td *ngIf="txt">{{ item.ENROLEDSTATUS1 }}</td>
                <td *ngIf="!txt">
                  {{
                    item.APPICATIONDATE1 == "NA" ? "NA" : item.APPICATIONDATE1
                  }}
                </td>
                <td *ngIf="!txt">{{ item.SIGNUPDATE1 }}</td>
                <td *ngIf="headerText == 'Admitted'">
                  {{ item.ADMITTEDDATE }}
                </td>
                <td *ngIf="headerText == 'Accepted'">
                  {{ item.ACCEPTEDDATE }}
                </td>
                <td>{{ item.USERNAME1 }}</td>
                <td>{{ item.INITIALUSERNAME }}</td>
                <td>{{ item.MOBILE1 }}</td>
                <td>{{ item.DOB1 }}</td>
                <td>{{ item.GENDER1 }}</td>
                <td>{{ item.COUNTRY_NAME }}</td>
                <td>{{ item.COUNTIES_NAME }}</td>
                <td>{{ item.SUB_COUNTIES_NAME }}</td>
                <td *ngIf="headerText == 'Initiated'">
                  {{ item.REGINITDATE }}
                </td>
                <td *ngIf="headerText == 'Enroled'">{{ item.REGAPPDATE }}</td>
                <!-- <td></td> -->
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center" *ngIf="!responseData.length">
          No Records to display
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="newsModal"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header py-2">
        <h4 class="modal-title w-100"></h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal">
          &times;
        </button>
        <!-- <h4 class="modal-title" >Upcoming Event</h4> -->
        <!-- <button type="button" class="close" data-dismiss="modal">&times;</button> -->
      </div>
      &nbsp;

      <div class="container">
        <div class="row col-12">
          <div class="col-3">
            <img [src]="news?.EVENT_IMAGE" alt="News" />
          </div>
          <div class="col-9">
            <h3>{{ news?.EVENT_NAME }}</h3>
            <P class="description">{{ news?.EVENT_DESCRIPTION }}</P>
          </div>
        </div>
      </div>
      <!-- <h5 class="description">{{news.EVENT_DESCRIPTION}}</h5> -->
      <div class="modal-footer">
        &nbsp;
        <button class="btn btn-danger" id="close" data-bs-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="myModal" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <!-- Modal Header -->
      <div class="modal-header py-2">
        <h6 class="modal-title w-100">Add News</h6>
        <button
          type="button"
          (click)="close()"
          class="close"
          data-bs-dismiss="modal"
        >
          <i class="fas fa-times"></i>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
        <form
          class="well form-horizontal"
          [formGroup]="myForm"
          (ngSubmit)="onSubmit(myForm)"
        >
          <fieldset>
            <div class="form-group row">
              <label class="col-md-4 control-label">
                News Type <span class="text-danger">*</span></label
              >
              <div class="col-md-8 inputGroupContainer">
                <div class="radio">
                  <strong>:</strong> &nbsp;
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        value="0"
                        formControlName="EVENT_TYPE"
                        type="radio"
                        class="form-check-input"
                        name="EVENT_TYPE"
                      />One
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="form-check-label">
                      <input
                        value="1"
                        type="radio"
                        formControlName="EVENT_TYPE"
                        class="form-check-input"
                        name="EVENT_TYPE"
                      />All
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row pt-4">
              <label class="col-md-4 control-label"
                >Status<span class="text-danger">*</span></label
              >
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <select
                    class="form-select shadow-sm rounded-lg"
                    id="sel1"
                    formControlName="EVENT_STATUS"
                  >
                    <option value="true">Active</option>
                    <option value="false">InActive</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="form-group row pt-4">
              <label class="col-md-4 control-label line_2 rem_1">
                Headline<span class="text-danger">*</span></label
              >
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <input
                    type="text"
                    placeholder=""
                    class="form-control"
                    formControlName="EVENT_NAME"
                  />
                </div>
              </div>
            </div>
            <div class="form-group row pt-4">
              <label class="col-md-4 control-label line_2 rem_1">
                Content<span class="text-danger">*</span></label
              >
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <textarea
                    placeholder=""
                    class="form-control"
                    formControlName="EVENT_DESCRIPTION"
                  ></textarea>
                </div>
              </div>
            </div>

            <div class="form-group row pt-4">
              <label class="col-md-4 control-label line_2 rem_1">
                Upload Image</label
              >
              <div class="col-md-8 inputGroupContainer">
                <div class="input-group">
                  <input
                    type="file"
                    #InputFile
                    (change)="changeFile($event)"
                    placeholder=""
                    class="form-control"
                  />
                  <div *ngIf="isEdit">
                    <p>{{ editData.EVENT_IMAGE_NAME }}</p>
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          *ngIf="!isEdit"
          class="btn btn-save"
          (click)="onSubmit(myForm)"
          [disabled]="myForm.invalid"
        >
          Save
        </button>
        <button
          type="button"
          *ngIf="isEdit"
          class="btn btn-save"
          (click)="onSubmit(myForm)"
          [disabled]="myForm.invalid"
        >
          Update
        </button>
        <button
          type="button"
          class="btn btn-danger"
          id="md_close"
          (click)="close()"
          data-bs-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
