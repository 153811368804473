import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-short-courses",
  templateUrl: "./short-courses.component.html",
  styleUrls: ["./short-courses.component.css"],
})
export class ShortCoursesComponent implements OnInit {
  roleId: any = localStorage.getItem("RoleId");
  constructor() {
    this.roleId = atob(this.roleId);
  }

  ngOnInit(): void {}
}
