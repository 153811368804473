import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CommonService } from "../../services/common.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";

@Component({
  selector: "app-view-program-details",
  templateUrl: "./view-program-details.component.html",
  styleUrls: ["./view-program-details.component.css"],
})
export class ViewProgramDetailsComponent implements OnInit {
  courseDetails: any;
  sessionDetails: ISessionDetails;
  isFirstLogin: boolean = localStorage.getItem("IsFirstLogin") === "true";
  courseYear: Array<any> = [];

  constructor(
    private CommonService: CommonService,
    private toastr: ToastrService,
    private route: Router,
    private location: Location
  ) {}

  ngOnInit(): void {
    const resultState: any = this.location.getState();
    delete resultState["navigationId"];
    // console.log(resultState);
    this.courseDetails = resultState;
    if (this.courseDetails)
      sessionStorage.courseDetails = JSON.stringify(this.courseDetails);
    else sessionStorage.removeItem("courseDetails");
    // this.courseDetails = Object.values(resultState) ;
    // console.log(this.courseDetails);
    if (this.courseDetails.moreoptions)
      this.courseDetails.moreoptions =
        this.courseDetails.moreoptions.split(",");
    else this.courseDetails.moreoptions = [];

    const courseId: number = this.courseDetails.COURSE_ID || 0;
    // if (courseId > 0) this.loadSessions(courseId);
  }
  openModal1() {
    // $('#myModal').modal('show'); // Open Modal 1
    let ApplicationType = localStorage.getItem("appTyp");
    this.CommonService.postCall("Registration/CheckUserByCourse", {
      USER_ID: localStorage.getItem("UserId"),
      COURSEID: localStorage.getItem("cID"),
      TNT_CODE: localStorage.getItem("TenantCode"),
    }).subscribe((res: any) => {
      if (res.registrationdetails == null) {
        this.CommonService.postCall(
          "Registration/CreateRegistrationDetailsFirst",
          {
            USER_ID: localStorage.getItem("UserId"),
            COURSEID: localStorage.getItem("cID"),
            CREATEDBY: localStorage.getItem("UserId"),
            MODIFIEDBY: localStorage.getItem("UserId"),
            TNT_CODE: localStorage.getItem("TenantCode"),
          }
        ).subscribe((res: any) => {
          if (!this.isFirstLogin && ApplicationType != "3") {
            this.route.navigate(["/HOME/components/oukRegForm"]);
          } else if (ApplicationType == "3") {
            this.route.navigate(["/HOME/components/pdsRegForm"], {
              queryParams: { courseId: 0, isBool: true },
            });
          }
        });
      } else if (res.registrationdetails != null && ApplicationType != "3") {
        return this.toastr.warning(
          "Application already in progress, please navigate to My Application"
        );
      } else {
        setTimeout(() => {
          this.CommonService.activateSpinner();
          if (!this.isFirstLogin && ApplicationType != "3") {
            this.route.navigate(["/HOME/components/oukRegForm"]);
          } else if (ApplicationType == "3") {
            this.route.navigate(["/HOME/components/pdsRegForm"], {
              queryParams: { courseId: 0, isBool: true },
            });
          } else {
            let payload = {
              // "ACADEMICYEAR_ID":,
              USER_ID: localStorage.getItem("UserId"),
              TENANT_CODE: localStorage.getItem("TenantCode"),
            };
            this.CommonService.postCall(
              "Registration/CheckMaxEnrollCourses",
              payload
            ).subscribe((res: any) => {
              if (res.message == false) {
                return this.toastr.warning(
                  "You are not allowed to apply for more than 1 programmes. Please contact administrator for further details."
                );
              } else {
                this.route.navigate(["HOME/components/regForm"]);
              }
            });
          }
          this.CommonService.deactivateSpinner();
        }, 1000);
      }
    });
  }
}

interface ISessionDetails {
  COURSESHD_AMOUNT: number;
  COURSESHD_COURSE_ID: number;
  COURSESHD_ENDDATE: string;
  COURSESHD_ENDTIME: string;
  COURSESHD_ID: number;
  COURSESHD_NAME: string;
  COURSE_FEES: number;
  COURSESHD_STARTDATE: string;
  COURSESHD_STARTTIME: string;
  REGISTRATION_STARTDATE: string;
  REGISTRATION_ENDDATE: string;
  COURSESHD_PAYMENT_METHOD: number;
  Total_Payable_Amount: number;
}
