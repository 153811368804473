<!-- Confirm Message for doctor to close the video call -->
<button
  type="button"
  class="d-none"
  data-toggle="modal"
  data-target="#confirmMessage"
  id="btnConfirmMessage"
></button>
<div
  class="modal fade"
  data-backdrop="static"
  data-keyboard="false"
  id="confirmMessage"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Confirmation Message</h5>
        <button
          type="button"
          id="btnConfirmMessageClose"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Student/Teacher has disconnected the Scheduled meeting.
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-primary  " onclick="window.webComponent.style.display='none'" -->
        <button type="button" class="btn btn-primary" data-dismiss="modal">
          Yes
        </button>
        <button
          type="button"
          class="btn btn-danger"
          data-dismiss="modal"
          (click)="gotoConsultation()"
        >
          No
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Button trigger modal -->
<button
  type="button"
  class="d-none"
  id="btnOpenVideo"
  data-bs-toggle="modal"
  data-bs-target="#OpenVideo"
></button>

<!-- Modal -->
<div
  class="modal fade"
  id="OpenVideo"
  data-bs-backdrop="static"
  data-bs-keyboard="false"
  tabindex="-1"
  aria-labelledby="staticBackdropLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Video Conference</h5>
        <button
          type="button"
          class="btn-close"
          (click)="closeVideoConference()"
          id="closeVideoModel"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body p-0">
        <div class="col-xl-12 h-100">
          <div class="">
            <div class="drag" id="drogVideo">
              <div class="card videobg shadow videoalign">
                <div class="card-header pb-1 pt-1 text-white mx-auto">
                  Video Conference
                </div>
                <div class="card-body p-0">
                  <div id="conference_div">
                    <div id="container" style="height: 285px !important">
                      <openvidu-webcomponent
                        style="display: none"
                      ></openvidu-webcomponent>
                    </div>
                    <div>
                      <input id="convId" type="hidden" />
                    </div>
                    <div>
                      <ul id="log" style="display: none"></ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card videobg shadow videoposition videoalign">
                      <div class="card-header pb-1 pt-1 text-white mx-auto">Video Consultation</div>
                      <div class="card-body p-4"></div>
                    </div> -->
        </div>
      </div>
      <!-- <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            </div> -->
    </div>
  </div>
</div>
